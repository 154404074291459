// libraries
import moment from 'moment';
import businessDays from 'moment-business-days';
import draftToHtml from 'draftjs-to-html';
import parse from 'html-react-parser';
import generator from 'generate-password-browser';
import React, { Fragment } from 'react';
// constants
import {
  MIME_TYPE, MAX_LENGTH_TRUNCATE_STRING,
  PASSWORD_REGEX_CHARACTERS, PASSWORD_REGEX_UPPERCASE,
  PASSWORD_REGEX_LOWERCASE, PASSWORD_REGEX_NUMBERS, DATE_FORMAT,
  USER_ROLES, UNASSIGNED_ID, PATIENT_CALL_TIME_OPTIONS, NONE_SPECIFIED_STRING, QA_STATUSES,
} from '../constants/constants';
import { CONFIG_NAME, INIT_HASHTAG } from '../constants/globalAdminUi';
import { DEPRECATED_INTERVENTIONS_TYPES, INTERVENTIONS_TYPES } from '../constants/interventions';
import { TYPE_OPTIONS } from '../constants/conditions';

export function validatePhone(phone) {
  let formattedPhone;

  if (phone && phone.length) {
    formattedPhone = phone.replace(/^\+?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4,6}$/im, '');
  }

  if (!formattedPhone) return false;
  return 'must contain 10 digits';
}

export function validateDigits(value) {
  if (value && /^\d+$/.test(value)) {
    return false;
  }
  return 'must contain only digits';
}

export function validateRequired(value) {
  if (value || value === 0) return false;
  return 'is required';
}
export function validateCleanString(value) {
  if (!value.match(/[^0-9a-zA-Z',.:()\-&_/ ]+/g, '')) {
    return false;
  }
  return 'must contain valid characters';
}

export function validateDigitsRequired(value) {
  return validateRequired(value) || validateDigits(value);
}

export function validatePhoneRequired(phone) {
  return validateRequired(phone) || validatePhone(phone);
}

export function validatePassword(password) {
  if (password && password.length > 7) {
    const haveCharacters = new RegExp(PASSWORD_REGEX_CHARACTERS);
    const haveUppercase = new RegExp(PASSWORD_REGEX_UPPERCASE);
    const haveLowercase = new RegExp(PASSWORD_REGEX_LOWERCASE);
    const haveNumbers = new RegExp(PASSWORD_REGEX_NUMBERS);
    if (
      haveCharacters.test(password) && haveUppercase.test(password)
      && haveLowercase.test(password) && haveNumbers.test(password)
    ) {
      return false;
    }
    return 'must contain digits, lowercase letters, uppercase letters and a special character(s)';
  }
  return 'minimum length is 8 symbols';
}

export function validateDate(date, {
  maxDate, minDate, maxDateName, minDateName, dateName, dateRange,
} = {}) {
  if (!date) return false;

  if (!moment(date).isValid()) {
    return 'must be a valid date';
  }

  const TODAY = moment();

  let isDateLessThanMax = true;

  let isDateGreaterThanMin = true;

  if (maxDate) {
    isDateLessThanMax = moment(date).isSameOrBefore(maxDate);
  }

  if (!isDateLessThanMax) {
    if (maxDateName) {
      return `${dateName} can't be later than ${maxDateName}`;
    }

    if (dateRange && moment(date).isSameOrBefore(TODAY)) {
      return `Date range can't be greater than ${dateRange} days`;
    }

    return 'must be a valid date';
  }

  if (minDate) {
    isDateGreaterThanMin = moment(date).isSameOrAfter(minDate);
  }

  if (!isDateGreaterThanMin) {
    if (minDateName) {
      return `${dateName} can't be earlier than ${minDateName}`;
    }

    if (dateRange) {
      return `Date range can't be greater than ${dateRange} days`;
    }

    return 'must be a valid date';
  }

  return false;
}

export function validateDateRequired(date, ...params) {
  return validateRequired(date) || validateDate(date, ...params);
}

export function validateICD10Code(code, regex) {
  if (regex.test(code)) return false;
  return 'must be a valid ICD10 code';
}

export function validateICD10CodeRequired(code, regex) {
  const trimmedCode = code.trim();

  return validateRequired(trimmedCode) || validateICD10Code(trimmedCode, regex);
}

export function validateActivityTime(time) {
  const number = Number(time);

  if (!Number.isInteger(number) || `${number}` !== `${time}`.replace(/\b(0(?!\b))+/g, '')) {
    return 'must be an integer';
  }

  if (number < 0 || number > 120) {
    return 'must be between 0 and 120';
  }

  return false;
}

export function validateActivityTimeRequired(time) {
  return validateRequired(time) || validateActivityTime(time);
}

export function formatNumbers(text) {
  if (!text || !text.length) return '';

  return text.replace(/\D/g, '');
}

export function formatPhone(phone, isWildcard = false) {
  const PHONE_DIGITS_MAX_COUNT = 11;
  const digitsPhone = formatNumbers(phone);

  if (isWildcard && phone === '*') return phone;
  if (!digitsPhone) return '';

  let newPhone;
  let phoneSlices;

  if (digitsPhone.length === PHONE_DIGITS_MAX_COUNT && phone.length === PHONE_DIGITS_MAX_COUNT) {
    phoneSlices = digitsPhone.match(/(\d?)(\d{0,3})(\d{0,3})(\d{0,4})/);
  } else {
    phoneSlices = digitsPhone.match(/(\d{0})(\d{0,3})(\d{0,3})(\d{0,4})/);
  }

  newPhone = !phoneSlices[3] ? phoneSlices[2] : `(${phoneSlices[2]}) `;
  newPhone += phoneSlices[3];
  newPhone += phoneSlices[4] ? `-${phoneSlices[4]}` : '';

  return newPhone;
}

export function formatPhoneForRequest(phone, isWildcard = false) {
  const phoneDigits = formatNumbers(phone);

  if (isWildcard && phone === '*') return phone;
  if (!phoneDigits) return null;
  return `1${phoneDigits}`;
}

export function formatPhoneOnlyDigits(phone) {
  return formatNumbers(phone) || null;
}

function base64toBlob(base64Data, contentType) {
  const sliceSize = 1024;
  const byteCharacters = atob(base64Data);
  const bytesLength = byteCharacters.length;
  const slicesCount = Math.ceil(bytesLength / sliceSize);
  const byteArrays = new Array(slicesCount);

  for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    const begin = sliceIndex * sliceSize;
    const end = Math.min(begin + sliceSize, bytesLength);

    const bytes = new Array(end - begin);
    for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0);
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }
  return new Blob(byteArrays, { type: contentType || '' });
}

/**
 * triggers file download
 * @param data -- base64 encoded string
 * @param filename -- filename
 */
export function downloadFile(data, filename) {
  let contentType;

  const fileExtension = filename.split('.').pop();

  switch (fileExtension.toLowerCase()) {
    case 'csv':
      contentType = MIME_TYPE.csv;
      break;

    case 'xls':
      contentType = MIME_TYPE.xls;
      break;

    case 'xlsx':
      contentType = MIME_TYPE.xlsx;
      break;

    case 'pdf':
    default:
      contentType = MIME_TYPE.pdf;
      break;
  }

  const blob = base64toBlob(data, contentType);

  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, filename);
  } else {
    const anchor = document.createElement('a');
    document.body.appendChild(anchor);
    anchor.style.display = 'none';
    anchor.setAttribute('download', filename);
    anchor.setAttribute('href', URL.createObjectURL(blob));
    setTimeout(() => {
      anchor.click();
    }, 0);
  }
}

export function isObjectsEqual(firstObjectData, secondObjectData) {
  const { hasOwnProperty } = Object.prototype;
  const firstObject = firstObjectData ? { ...firstObjectData } : {};
  const secondObject = secondObjectData ? { ...secondObjectData } : {};

  let result = Object.keys(firstObject).every((key) => {
    const firstElement = firstObject[key];
    const secondElement = secondObject[key];

    if (!hasOwnProperty.call(secondObject, key)
      || typeof (firstElement) !== typeof (secondElement)) {
      return false;
    }

    switch (typeof (firstElement)) {
      case 'object':
        if (!isObjectsEqual(firstElement, secondElement)) {
          return false;
        }
        break;
      case 'function':
        if (firstElement.toString() !== secondElement.toString()) {
          return false;
        }
        break;
      default:
        if (firstElement !== secondElement) {
          return false;
        }
    }

    return true;
  });

  // Check second object for any extra properties
  result = result && Object.keys(secondObject)
    .every(key => hasOwnProperty.call(firstObject, key));

  return result;
}

export const isObjectEmpty = (object = {}) => Object.getOwnPropertyNames(object).length === 0;

export const formQueryString = (params = {}) => {
  let queryString = '';

  Object.keys(params).forEach((name) => {
    if (params[name] || params[name] === 0) {
      if (!queryString) {
        queryString += '?';
      } else {
        queryString += '&';
      }
      queryString += `${name}=${encodeURIComponent(params[name])}`;
    }
  });

  return queryString;
};

export const formQueryStringWithoutSign = (params = {}) => {
  let queryString = '';

  Object.keys(params).forEach((name) => {
    if (params[name] || params[name] === 0) {
      if (!queryString) {
        queryString += '&';
      } else {
        queryString += '&';
      }
      queryString += `${name}=${encodeURIComponent(params[name])}`;
    }
  });

  return queryString;
};

const holidaysWithoutOffset = (holidays = []) => holidays.map(holiday => holiday.holidayDate);

export function getDaysRemaining(holidays = []) {
  businessDays.updateLocale('us', {
    holidays: holidaysWithoutOffset(holidays),
    holidayFormat: DATE_FORMAT.FULL_SERVER,
  });
  const endOfMonth = moment().endOf('month');
  const today = moment();
  const diff = businessDays(endOfMonth, DATE_FORMAT.FULL_SERVER)
    .businessDiff(today, DATE_FORMAT.FULL_SERVER);

  // Add 1 day (today's date) to the logic
  return diff + 1;
}

export function validateICD10CodeYup(code, regex) {
  return regex.test(code);
}

export const longStringTruncate = (str, n = MAX_LENGTH_TRUNCATE_STRING, useWordBoundary = true) => {
  if (!str) {
    return '';
  }
  if (str && str.length <= n) {
    return str;
  }
  const subString = str.substr(0, n - 1);
  return `${useWordBoundary ? subString.substr(0, subString.lastIndexOf(' '))
    : subString}...`;
};

export const parseDraftToHtml = (text) => {
  try {
    return parse(draftToHtml(JSON.parse(text)));
  } catch (e) {
    return text;
  }
};

export const parseStringToDraft = (text) => {
  try {
    return JSON.parse(text) && text;
  } catch (e) {
    const newText = text.replace(/\n/g, ' ');
    return `{"blocks":[{"key":"4u1s7","text":"${newText}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`;
  }
};

export const isRichEditorText = text => text && text.startsWith('{') && text.endsWith('}');

export const getTagData = (tagId, tags) => tags.find(tag => tag.id === tagId);

export const getEventType = (eventId, eventTypes) => eventTypes.find(event => event.id === eventId);

export const autoGeneratePassword = () => {
  const password = generator.generate({
    length: 12,
    numbers: true,
    symbols: true,
    exclude: '=+\'',
    strict: true,
  });

  return password;
};

export const copyToClipboard = (str) => {
  if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
    return navigator.clipboard.writeText(str);
  }
  return Promise.reject('The Clipboard API is not available.');
};

export const getTenant = () => {
  let tenant = '';
  try {
    const match = window.location.hash.match(/#\/([^/]*)($|\/)/)[1];
    if (match) {
      tenant = match;
    }
  } catch (e) {
  }
  if (tenant === INIT_HASHTAG) tenant = CONFIG_NAME;
  return tenant;
};

export const draftToString = blocks => blocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n');

export const getPlainValueFromDraft = (value, optionalValue = '') => {
  const rawValue = value || optionalValue;
  return isRichEditorText(rawValue)
    ? draftToString(JSON.parse(rawValue).blocks) : rawValue;
};

export const isLessThanXHours = (date, hours) => moment(moment.utc(date)).local().isBetween(
  moment().subtract(hours, 'hours'), moment(),
);

export const showFormattedText = (text) => {
  if (text) {
    if (isRichEditorText(text)) return getPlainValueFromDraft(text);
    return text;
  }
  return '';
};

export const defineNewSuspendStatus = (patient) => {
  switch (patient.status) {
    case 'N':
    case 'P':
    case 'P2':
    case 'P3':
    case 'PN':
      return 'PS';
    default:
      return 'CS';
  }
};

export const getPrimaryStatus = (patient) => {
  if (!patient || !patient.status) {
    return null;
  }
  return patient.status.charAt(0);
};

// Get the patient ID from a patient object
export const getPatientId = (patient) => {
  const foundIdentifier = patient?.identifiers?.find(identifier => identifier && identifier.IDType === 'anita_pid');
  return foundIdentifier ? foundIdentifier.ID : undefined;
};

export const prepareCnList = (cnListParam = [], user) => {
  const newCnList = [];
  newCnList.push({
    value: '',
    label: 'All patients',
  });
  if (user.role !== USER_ROLES.ADMIN) {
    newCnList.push({
      value: user.id,
      label: 'My patients',
    });
  }

  newCnList.push({
    value: '0',
    label: 'Unassigned',
  });

  if (user.role === USER_ROLES.ADMIN) {
    newCnList.push({
      value: -1,
      label: 'Assigned to Disabled User',
    });
  }

  cnListParam.forEach((cn) => {
    if (user.id === cn.id) {
      return;
    }
    newCnList.push({
      ...cn,
      value: cn.id,
      label: `${cn.lastName ? `${cn.lastName}, ` : ''}${cn.firstName ? cn.firstName : ''}`,
    });
  });

  return newCnList;
};

export function checkAssignedOwner(listOwners, ownerId) {
  if (!ownerId || (ownerId === UNASSIGNED_ID)) {
    return true;
  }

  let result = false;

  listOwners.forEach((owner) => {
    if (owner.id === ownerId) {
      result = true;
    }
  });

  return result;
}

export function prepareCareNavigatorsList(cnList = []) {
  const list = [
    {
      value: UNASSIGNED_ID,
      label: 'Not Assigned',
    },
    ...cnList.map(cn => ({
      ...cn,
      value: cn.id,
      label: `${cn.lastName ? `${cn.lastName},` : ''} ${cn.firstName ? cn.firstName : ''}`,
    })),
  ];
  list.sort((a, b) => {
    const textA = a.lastName || '';
    const textB = b.lastName || '';
    return textA.toUpperCase().localeCompare(textB.toUpperCase());
  });
  return list;
}

export const getCallTimeLabel = (callTime) => {
  const callTimeOption = PATIENT_CALL_TIME_OPTIONS.find(el => el.value === callTime);
  return callTimeOption ? callTimeOption.name : NONE_SPECIFIED_STRING;
};

export const isWeekend = (date) => {
  const day = date.getDay();
  return day === 0 || day === 6;
};

const holidaysInDate = (holidays = []) => holidays.map(holiday => moment.utc(`${holiday.holidayDate}T12:00:00.000Z`).toDate());

export const isHoliday = (date, holidays = []) => {
  const holidayIndex = holidaysInDate(holidays).findIndex(
    holidayDate => holidayDate.getDate() === date.getDate()
      && holidayDate.getMonth() === date.getMonth()
      && holidayDate.getFullYear() === date.getFullYear(),
  );
  return holidayIndex >= 0;
};

export const isWeekendOrHoliday = (date, isCss = true, holidays = []) => {
  const isWeekendDate = isWeekend(date);
  const isHolidayDate = isHoliday(date, holidays);
  if (isWeekendDate && isHolidayDate) {
    return isCss ? 'text-ccm-lipstick text-underline' : 'Note: date selected is a Company holiday and weekend';
  } if (isWeekendDate) {
    return isCss ? 'text-ccm-orange text-underline' : 'Note: date selected is weekend';
  } if (isHolidayDate) {
    return isCss ? 'text-ccm-red text-underline' : 'Note: date selected is a Company holiday';
  }
  return '';
};

export const fetchData = async (request) => {
  const loadRequestPromise = request.promise;

  let requestedData = [];
  try {
    requestedData = await loadRequestPromise;
  } catch (error) {
    delete request.promise;
    if (error.status === 401 || error.status === 403) {
      return [];
    }
  }
  delete request.promise;
  return requestedData;
};

export const getDateExcludingHolidays = (months, holidays = []) => {
  const currentDate = moment();
  let nextMonthsDay = currentDate.add(months, 'months');
  while (isHoliday(nextMonthsDay.toDate(), holidays) || isWeekend(nextMonthsDay.toDate())) {
    nextMonthsDay = nextMonthsDay.add(1, 'day');
  }
  return nextMonthsDay;
};

export const getContactMethodIcon = (type) => {
  if (type === 'phone') return 'telephone-fill';
  if (type === 'text') return 'chat-right-dots';
  return '';
};

export const getCategories = () => {
  const categories = [];
  Object.keys(INTERVENTIONS_TYPES).forEach((key) => {
    categories.push({
      value: key,
      label: INTERVENTIONS_TYPES[key].title,
    });
  });
  categories.sort((current, next) => current.label.localeCompare(next.label));
  return categories;
};

export const getSubcategories = data => data.sort().map(option => ({
  value: option,
  label: option,
}));

export const getInterventionType = (type) => {
  if (INTERVENTIONS_TYPES[type]) {
    return INTERVENTIONS_TYPES[type].title;
  } if (DEPRECATED_INTERVENTIONS_TYPES[type]) {
    return DEPRECATED_INTERVENTIONS_TYPES[type].title;
  }
  return type;
};

export const isInCurrentMonth = (specificDate) => {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  const inputDate = new Date(specificDate);
  const inputMonth = inputDate.getMonth();
  const inputYear = inputDate.getFullYear();

  return inputMonth === currentMonth && inputYear === currentYear;
};

export const getQaNoteInfo = (status) => {
  switch (status) {
    case QA_STATUSES.requested.type:
      return (
        <Fragment>
          <span className="bi-exclamation-triangle mr-1" />
          QA
        </Fragment>
      );
    case QA_STATUSES.resubmitted.type:
      return (
        <Fragment>
          <span className="bi-arrow-clockwise mr-1" />
          QA
        </Fragment>
      );
    case QA_STATUSES.acceptFeedback.type:
      return (
        <Fragment>
          <span className="bi-info-circle mr-1" />
          QA
        </Fragment>
      );
    default:
      return '';
  }
};

export const isConditionUniqueSelected = (array, condition, type) => (
  type && type !== TYPE_OPTIONS()[0].value
    ? array && array.length === 1
    && array[0].type === condition.type && array[0].name === condition.name
    : array === condition.name
);

// helper method to filter any manual input values not strictly fitting the 'MM/dd/yyyy' format
export function conditionalDateFormatFilter(rawValue, setFieldValue, setErrorMessage, field) {
  const validRegex = /\d{2}\/\d{2}\/\d{4}/i;
  if (!validRegex.test(rawValue)) {
    setFieldValue(field.name, null);
    setErrorMessage('required');
  }
}
