import { useField } from 'formik';
import React from 'react';
import { Form } from 'react-bootstrap';

export const TextArea = ({
  label, subtext, styles = {}, rows, isScreening = false, ...props
}) => {
  const [field, meta] = useField(props);

  return (
    <Form.Group>
      <Form.Label htmlFor={props.id || props.name} className={styles.formLabel}>{label || ''}</Form.Label>
      <Form.Control
        as="textarea"
        rows={rows}
        aria-describedby={`${props.name} Text Area`}
        onChange={props.handleChange}
        {...(!isScreening ? {
          isValid: meta.value && (meta.touched && !meta.error),
        }
          : {})}
        isInvalid={meta.error}
        {...field}
        {...props}
      />
      {subtext && <Form.Text muted>{subtext}</Form.Text>}
      <Form.Control.Feedback type="invalid" muted>{meta.error}</Form.Control.Feedback>
    </Form.Group>
  );
};

export const ScreeningTextArea = ({
  label, subtext, styles = {}, rows, ...props
}) => (
    <>
      <TextArea
        label={label}
        subtext={subtext}
        styles={styles}
        rows={rows}
        isScreening
        {...props}
      />
    </>
);
