// Libraries
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
// Views
import QaNotes from '../patientNotes/QaNotes';
import CallNotes from '../patientNotes/CallNotes';
import PersonalNotes from '../patientNotes/PersonalNotes';
import EnrollmentNotes from '../patientNotes/EnrollmentNotes';
import BillingStatus from './BillingStatus';
import EligibilityRules from '../EligibilityRules';
import PatientAttributes from './PatientAttributes';
import PatientAvailability from './PatientAvailability';
import SummaryStatusInfo from './SummaryStatusInfo';
import SummaryAnnualWellness from './SummaryAnnualWellness';
import PrimaryCarePhysicians from './PrimaryCarePhysicians';
import Insurance from '../insurance/Insurance';
// Constants
import { USER_ROLES, TENANT_FEATURES_NAMES } from '../../../constants/constants';
import { STATUSES } from '../../../constants/statuses';

export const PatientSummaryInfo = (props) => {
  const { reloadPatientInfo, setChangeStatusView } = props;
  const { id: patientId } = useParams();
  const {
    user: { id: userId, role: userRole, isExternal: isExternalUser = true } = {},
    patient: {
      status, language, assignedCareNavigator,
    } = {},
    tenant: { features, isReadOnly = false } = {},
  } = useSelector(state => state);

  const isCnUser = (userRole === USER_ROLES.CN);
  const isAdminUser = (userRole === USER_ROLES.ADMIN);
  const isCnOwner = (assignedCareNavigator && assignedCareNavigator.id === userId);

  const showPatientAvailability = features
    && features.find(f => f.featureName === TENANT_FEATURES_NAMES.ENABLE_PRE_CALL_TEXTS.name)
      .enabled
    && features.find(f => f.featureName === TENANT_FEATURES_NAMES.ENABLE_TEXT_INTEGRATION.name)
      .enabled;

  let eligibilityRulesBlock = '';
  const isStatusBilled = !!STATUSES.C.subStatuses[status];
  if (isStatusBilled) {
    eligibilityRulesBlock = (
      <EligibilityRules patientId={patientId} isDataSaved />
    );
  }

  return (
    <Fragment>
      <div className="patient-summary-status box-wrapper d-flex-center-between p-3 my-3">
        <SummaryStatusInfo
          setChangeStatusView={setChangeStatusView}
          reloadPatientInfo={reloadPatientInfo}
          isReadOnly={isReadOnly}
        />
      </div>

      {showPatientAvailability && <PatientAvailability reloadPatientInfo={reloadPatientInfo} />}

      <div className="patient-summary-general bg-ccm-light-gray row no-gutters p-2 my-3">
        {!isExternalUser && (
          <div className="col-12 box-wrapper text-left">
            <PatientAttributes
              patientId={patientId}
              isReadOnly={isReadOnly}
              data-test="patientSummaryInfo_patientAttributes"
            />
          </div>)
        }
        <div className="col-6 text-left">
          <div className="box-wrapper patient-pcp-info my-2 p-2">
            <PrimaryCarePhysicians />
          </div>
          <div className="box-wrapper patient-awv-info my-2 p-2">
            <SummaryAnnualWellness />
          </div>
          <Insurance />
          <div className="box-wrapper my-2 p-2">
            <span className="mr-2">Patient language:</span>
            <span>{language || 'Unknown'}</span>
          </div>
          <div className="box-wrapper my-2 p-2">
            <BillingStatus />
            {eligibilityRulesBlock}
          </div>
        </div>
        <div className="col text-left ml-2">
          {(isAdminUser || (isCnUser && isCnOwner)) ? (
            <div className="box-wrapper my-2 p-2">
              <QaNotes
                patientId={patientId}
                isReadOnly={isReadOnly}
                data-test="patientSummaryInfo_qaNotes"
              />
            </div>
          ) : ''}
          <div className="box-wrapper my-2 p-2">
            <CallNotes patientId={patientId} />
          </div>
          <div className="box-wrapper my-2 p-2">
            <PersonalNotes
              patientId={patientId}
              isReadOnly={isReadOnly}
              data-test="patientSummaryInfo_personalNotes"
            />
          </div>
          <div className="box-wrapper my-2 p-2">
            <EnrollmentNotes
              patientId={patientId}
              isReadOnly={isReadOnly}
              data-test="patientSummaryInfo_enrollmentNotes"
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default PatientSummaryInfo;
