// Libraries
import React, { Fragment, useState } from 'react';
import moment from 'moment';
import {
  Button, ButtonGroup, Dropdown, DropdownButton,
} from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
// Services
import { updateNextAppointment } from '../../../services/patient';
// Views
import NextAppointmentModal from './NextAppointmentModal';
import PCPNumber from './PCPNumber';
// Actions
import ShowNotification from '../../../actions/notification';
import { UpdatePatient } from '../../../actions/patient';
// Constants
import {
  DATE_FORMAT, EMPTY_STRING,
  NONE_SPECIFIED_STRING,
  NOTIFICATION_TYPE,
  TENANT_FEATURES_NAMES,
} from '../../../constants/constants';

export const PrimaryCarePhysicians = () => {
  const {
    patient: {
      primaryPhysicians = [], latestPcpVisitDate, nextAppointment,
      nextWellnessVisit, billing, assignerCode,
    } = {},
    tenant: { features, isReadOnly = false } = {},
  } = useSelector(state => state);
  const dispatch = useDispatch();
  const showNotification = data => dispatch(ShowNotification(data));
  const [nextApptModalOpened, setNextApptModalOpened] = useState(false);

  const showPcpPhone = features && features.length > 0
    && (features.find(f => f.featureName === TENANT_FEATURES_NAMES.ENABLE_PCP_PHONE.name)
      ?.enabled ?? false);
  const showTelehealthGroup = features && features.length > 0
    && (features.find(f => f.featureName === TENANT_FEATURES_NAMES.ENABLE_MULTI_TELEHEALTH.name)
      ?.enabled ?? false);

  const defaultPrimaryPhysician = primaryPhysicians[0];
  const primaryPhysiciansMore = primaryPhysicians.length > 1 && (
    <DropdownButton
      as={ButtonGroup}
      variant="link"
      className="py-0"
      title={`${primaryPhysicians.length - 1} more`}
      disabled={isReadOnly}
      data-test="primaryCarePhysicians_more"
    >
      {primaryPhysicians.slice(1).map((item, index) => (
        <Dropdown.ItemText
          key={`pcp-${index}`}
          className="physician__name"
        >
          {`${item.firstName || ''} ${item.middleName || ''} ${item.lastName || ''} ${item.location ? `(${item.location})` : ''}`}
        </Dropdown.ItemText>
      ))}
    </DropdownButton>
  );

  const nextAppointmentDate = nextAppointment && nextAppointment.nextAppointmentDate
    ? moment(nextAppointment.nextAppointmentDate).format(DATE_FORMAT.FULL_WITH_TIME_IN_12_HOURS)
    : '';

  const getPhysicianName = () => {
    if (defaultPrimaryPhysician) {
      return (
        <Fragment>
          <span className="physician__name" data-test="primaryCarePhysicians_physicianName">
            {`${defaultPrimaryPhysician.firstName || ''} ${defaultPrimaryPhysician.middleName || ''} ${defaultPrimaryPhysician.lastName || ''} ${defaultPrimaryPhysician.location ? `(${defaultPrimaryPhysician.location})` : ''}`}
          </span>
          {primaryPhysiciansMore}
        </Fragment>
      );
    }
    return (<span>no PCP in EMR</span>);
  };

  const getBillingProvider = () => {
    if (billing?.billingInfo?.physicianDisplayName) {
      let provider = billing.billingInfo.physicianDisplayName;

      if (billing.billingInfo.physicianLocationName) {
        provider = `${provider} (${billing.billingInfo.physicianLocationName})`;
      }

      return provider;
    }

    return 'None';
  };

  const clearNextAppointment = (nextAppointmentParam, nextAppointmentKey) => {
    const clearedAppointment = {
      id: nextAppointmentParam.id,
      physician: { id: null },
      nextAppointmentDate: null,
    };

    if (nextAppointmentParam && nextAppointmentParam.id) {
      const nextAppointmentRequest = updateNextAppointment(clearedAppointment);
      const nextAppointmentPromise = nextAppointmentRequest.promise;

      return nextAppointmentPromise.then((data) => {
        delete nextAppointmentRequest.promise;

        dispatch(UpdatePatient({ [nextAppointmentKey]: data }));
      }).catch((error) => {
        delete nextAppointmentRequest.promise;
        if (error.isCanceled || error.status === 401 || error.status === 403) {
          return;
        }
        showNotification({
          message: 'An error occurred while attempting to save next appointment',
          autoHide: true,
          notificationType: NOTIFICATION_TYPE.ERROR,
        });
      });
    }
    showNotification({
      message: 'No Next Appointment has been created',
      autoHide: true,
      notificationType: NOTIFICATION_TYPE.ERROR,
    });
    return {};
  };

  return (
    <Fragment>
      <div className="d-flex flex-column mb-2">
        <div className="d-flex justify-content-between">
          <span>Primary Care Physician:</span>
          <Button
            size="lg"
            variant="link"
            className="p-0 m-0"
            onClick={() => setNextApptModalOpened(true)}
            data-test="primaryCarePhysicians_setNextAppointButton"
            disabled={isReadOnly}
          >
            <span className="d-flex-center bi-pencil-square" />
          </Button>
        </div>
        <div className="primaryCare-physicians d-flex align-items-center">
          {getPhysicianName()}
        </div>
      </div>
      {showPcpPhone && (
        <PCPNumber phoneNumber={defaultPrimaryPhysician
          && defaultPrimaryPhysician.phones.length > 0
          ? defaultPrimaryPhysician.phones[0] : EMPTY_STRING}
        />
      )}
      <div className="mb-2">
        <span className="text-ccm-gray mr-2">Last PCP Visit:</span>
        <span className="text-ccm-gray" data-test="primaryCarephysician_lastPcpVisit">
          {latestPcpVisitDate ? moment(latestPcpVisitDate).format(DATE_FORMAT.FULL) : 'Unknown'}
        </span>
      </div>
      <div className="d-flex align-items-center mb-2">
        <div>
          <span className="mr-2">Next PCP Visit:</span>
          <span
            className="next-apt-visit"
            data-test="primaryCarePhysicians_inputNextAppointButton"
            onClick={!isReadOnly ? () => setNextApptModalOpened(true) : null}
          >
            {nextAppointmentDate || NONE_SPECIFIED_STRING}
          </span>
        </div>
        {nextAppointmentDate && (
          <Button
            size="lg"
            variant="link"
            className="p-0 ml-3"
            onClick={() => clearNextAppointment(nextAppointment, 'nextAppointment')}
            data-test="primaryCarePhysicians_clearNextAppointButton"
            disabled={isReadOnly}
          >
            <span className="d-flex-center bi-x" />
          </Button>)}
      </div>
      {nextApptModalOpened && (
        <NextAppointmentModal
          initialPCPAppointment={nextAppointment}
          initialAWVAppointment={nextWellnessVisit}
          isModalOpen={nextApptModalOpened}
          setIsModalOpen={setNextApptModalOpened}
        />
      )}
      <div className="d-flex align-items-center mb-2">
        <div>
          <span className="mr-2">Billing Provider:</span>
          <span>{getBillingProvider()}</span>
        </div>
      </div>
      {showTelehealthGroup && (
        <div className="d-flex align-items-center">
          <div>
            <span className="mr-2">Telehealth Group:</span>
            <span data-test="primaryCarePhysicians_telehealthGroup">{ assignerCode ?? '---' }</span>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default PrimaryCarePhysicians;
