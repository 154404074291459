// libraries
import React, { useEffect } from 'react';
import Modal from 'react-modal';
import { Routes, Route, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
// views
import TopBar from './components/topBar/TopBar';
import Banner from './components/base/Banner';
import CCMNotification from './components/base/Notification';
import Footer from './components/footer/Footer';
import Tenant from './components/base/entity/Tenant';
import NotFound from './components/base/errors/404';
import ConfirmContextProvider from './context/ConfirmContextProvider';
import ConfirmModal from './components/shared/ConfirmModal';
import GlobalTenant from './components/global/base/entity/GlobalTenant';
import GlobalTopBar from './components/global/topBar/GlobalTopBar';
import AmazonRum, { recordPageView } from './services/amazonRum';

export const App = (props) => {
  const { user, tenant } = props;

  const location = useLocation();

  useEffect(() => {
    recordPageView(location);
  }, [location]);

  useEffect(() => {
    if (document.getElementById('root')) {
      Modal.setAppElement('#root');
    }
  });

  if (tenant.globalTenant) {
    return (
      <main className="ccm-main container-fluid" role="main">
        <AmazonRum />
        <ConfirmContextProvider>
          <Banner />
          <div className="d-flex flex-column h-100">
            <div className="ccm-header">
              <Routes>
                <Route path=":tenant/*" element={user.authData ? <GlobalTopBar /> : null} />
              </Routes>
            </div>
            <div className="ccm-body">
              <Routes>
                <Route path=":tenant/*" element={<GlobalTenant />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </div>
            <div className="ccm-footer">
              <Footer />
            </div>
          </div>
          <CCMNotification />
          <ConfirmModal />
        </ConfirmContextProvider>
      </main>
    );
  }

  return (
    <main className="ccm-main container-fluid" role="main">
      <AmazonRum />
      <ConfirmContextProvider>
        <Banner />
        <div className="d-flex flex-column h-100">
          <div className="ccm-header">
            <Routes>
              <Route path=":tenant/*" element={user.authData ? <TopBar /> : null} />
            </Routes>
          </div>
          <div className="ccm-body">
            <Routes>
              <Route path=":tenant/*" element={<Tenant />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
          <div className="ccm-footer">
            <Footer />
          </div>
        </div>
        <CCMNotification />
        <ConfirmModal />
      </ConfirmContextProvider>
    </main>
  );
};

function mapStateToProps(state) {
  return {
    user: state.user,
    tenant: state.tenant,
  };
}

export default connect(mapStateToProps, null)(App);
