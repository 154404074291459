// Libraries
import React, {
  useState, useEffect, useMemo, useRef,
} from 'react';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _debounce from 'lodash/debounce';
import { connect } from 'react-redux';
import moment from 'moment';
import { Button } from 'react-bootstrap';
// Actions
import { UpdatePatient } from '../../actions/patient';
import ShowNotification from '../../actions/notification';
import { BlockRouteTransitions, UnBlockRouteTransitions } from '../../actions/router';
// Services
import {
  getPatientSingleCallNote, addPatientCallNote, updatePatientCallNote,
} from '../../services/patient';
import { parseStringToDraft } from '../../services/helpers';
// Constants
import { DATE_FORMAT, NOTIFICATION_TYPE } from '../../constants/constants';
// Views
import NoteEditor from '../shared/NoteEditor';

const NEW_NOTE = { id: null, note: '' };
const NOTE_STATE = {
  NONE: 'NONE',
  SAVED: 'SAVED',
  SAVING: 'SAVING',
  CHECKING: 'CHECKING',
};
const ELEMENT_NAME_TO_BLOCK = 'isEditingCallNotes';

export const CallNotes = (props) => {
  const {
    patient: { callNotes: historyCallNotes = [] } = {},
    patientId, isPanelOpened, loading,
    blockTransitions, unblockTransitions, isReadOnly = false,
  } = props;

  const [newNote, setNewNote] = useState(NEW_NOTE);
  const [noteState, setNoteState] = useState(NOTE_STATE.NONE);
  const [archiveDisabled, setArchiveDisabled] = useState(true);

  const prevNoteRef = useRef();
  useEffect(() => {
    prevNoteRef.current = newNote;
  }, [newNote]);
  const prevNote = prevNoteRef.current;

  const noteIsNotEmpty = noteText => !!noteText && JSON.parse(parseStringToDraft(noteText)).blocks[0].text !== '';

  const handleUpdateNote = (noteText) => {
    setNewNote({
      ...newNote,
      note: noteText,
    });
  };

  const debouncedChangeHandler = useMemo(() => _debounce(handleUpdateNote, 2000), [newNote]);

  const checkNote = (noteText) => {
    setArchiveDisabled(true);
    if (noteIsNotEmpty(noteText)) {
      setNoteState(NOTE_STATE.CHECKING);
      debouncedChangeHandler(noteText);
    }
  };

  const loadNote = (note) => {
    const { showNotification } = props;

    const getNoteRequest = getPatientSingleCallNote(patientId, note.id);
    const getNotePromise = getNoteRequest.promise;

    return getNotePromise.then((data) => {
      delete getNoteRequest.promise;
      setNewNote(data);
    }).catch((error) => {
      delete getNoteRequest.promise;

      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }

      showNotification({
        message: 'Could not load selected note, please try again later',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  const savePatientCallNote = (note) => {
    const { patient: { callNotes }, updatePatient, showNotification } = props;

    const updateCallNoteRequest = !note.id
      ? addPatientCallNote(patientId, note)
      : updatePatientCallNote(patientId, note);
    const updateCallNotePromise = updateCallNoteRequest.promise;

    return updateCallNotePromise.then((data) => {
      debouncedChangeHandler.cancel();
      delete updateCallNoteRequest.promise;

      let updatedCallNotes = [];
      if (!note.id) {
        updatedCallNotes = [...callNotes, data];
      } else {
        updatedCallNotes = callNotes.map(el => (el.id === data.id ? data : el));
      }

      if (!data.iscurrent) setNewNote(NEW_NOTE);
      else if (!note.id || note.note !== data.note) setNewNote(data);

      updatePatient({ callNotes: updatedCallNotes });
      unblockTransitions(ELEMENT_NAME_TO_BLOCK);
      setNoteState(NOTE_STATE.SAVED);
      setArchiveDisabled(false);
    }).catch((error) => {
      delete updateCallNoteRequest.promise;
      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }

      showNotification({
        message: 'An error occurred while attempting to save this note.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  const archiveNote = () => {
    if (noteIsNotEmpty(newNote.note)) {
      savePatientCallNote({ ...newNote, iscurrent: false });
      setNoteState(NOTE_STATE.SAVING);
      debouncedChangeHandler.cancel();
    }
  };

  useEffect(() => {
    if (newNote && prevNote
        && noteIsNotEmpty(newNote.note)
        && (newNote.note !== prevNote.note)) {
      blockTransitions(ELEMENT_NAME_TO_BLOCK, true);
      setNoteState(NOTE_STATE.SAVING);
      savePatientCallNote(newNote);
    } else {
      setNoteState(NOTE_STATE.NONE);
      setArchiveDisabled(false);
      unblockTransitions(ELEMENT_NAME_TO_BLOCK);
    }
  }, [newNote]);

  useEffect(() => {
    const sortedNotes = _reverse(_sortBy(historyCallNotes, el => moment(el.createddt)));
    if (sortedNotes.length > 0) {
      const lastCurrentNote = sortedNotes.find(el => el.iscurrent);
      if (lastCurrentNote && (!newNote.id || (lastCurrentNote.id !== newNote.id))) {
        loadNote(lastCurrentNote);
      }
    }

    return () => {
      debouncedChangeHandler.cancel();
    };
  }, []);

  const renderLoadingLabel = () => {
    if (noteState === NOTE_STATE.SAVING) return 'Auto saving call note...';
    if (noteState === NOTE_STATE.CHECKING) return 'Checking for note changes...';
    if (newNote && (newNote.updateddt || newNote.createddt)) {
      return `Last saved ${moment.utc(newNote.updateddt || newNote.createddt).format(DATE_FORMAT.FULL_WITH_TIME_IN_12_HOURS)}`;
    }
    return '';
  };

  return (
    <div className="ccm-patient-call_notes d-flex flex-column flex-grow-1 overflow-hidden mt-3" data-test="callNotes_modal">
      <div className="d-flex-center position-relative mb-1">
        <i className="bi-journal-text mr-1" data-for="tooltip-patientPanel" data-tip="Call Notes" />
        {isPanelOpened && <span className="mr-auto" data-test="callNotes_headingText">Call Notes</span>}
      </div>
      {isPanelOpened && (
      <div className="border rounded flex-grow-1 overflow-hidden mb-2">
        <NoteEditor
          note={newNote}
          handleChangeQaNote={noteText => checkNote(noteText)}
          preventToClearNote
          data-test="callNotes_noteEditor"
          readOnly={isReadOnly}
        />
      </div>)}
      {isPanelOpened && (
      <div>
        <small className="d-block text-ccm-gray mb-2" data-test="callNotes_LoadingLabel">
          {renderLoadingLabel()}
        </small>
        <Button
          size="xs"
          variant="success"
          onMouseDown={() => archiveNote()}
          disabled={isReadOnly || !newNote.id || loading || archiveDisabled}
          data-test="callNotes_saveBtn"
          block
        >
          Archive
        </Button>
      </div>)}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    patient: state.patient,
    loading: state.requestsInProgress.count,
    isReadOnly: state.tenant && state.tenant.isReadOnly,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updatePatient: patientData => dispatch(UpdatePatient(patientData)),
    showNotification: notificationData => dispatch(ShowNotification(notificationData)),
    blockTransitions: (elem, value) => dispatch(BlockRouteTransitions(elem, value)),
    unblockTransitions: elem => dispatch(UnBlockRouteTransitions(elem)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CallNotes);
