// Libraries
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import IdleTimer from 'react-idle-timer';
import moment from 'moment';
import { Alert, Button } from 'react-bootstrap';
import { Routes, Route, Navigate } from 'react-router-dom';
import _cloneDeep from 'lodash/cloneDeep';
// Actions
import {
  UpdateUserAuth, UpdateUserGoals, UpdateUserStatus, UpdateAnniversary,
} from '../../../actions/user';
import { SetCnList } from '../../../actions/careNavigators';
import { SetPesList } from '../../../actions/patientEnrollmentSpecialist';
import { LoadEmptySession, LoadSession, SetDefaultSearchParams } from '../../../actions/search';
import { SetBillingPhysiciansList } from '../../../actions/physicians';
import SetRoles from '../../../actions/roles';
import ShowNotification from '../../../actions/notification';
import {
  SetTenantTimezone, SetIsDemoEnv, SetTenantTags, SetTenantFeatures,
} from '../../../actions/tenants';
import { UpdatePatientUtils } from '../../../actions/patient';
import { SetDefaultQaSearchParams } from '../../../actions/qaSearch';
import { UnBlockRouteTransitions } from '../../../actions/router';
import ShowBanner from '../../../actions/banner';
// Services
import {
  checkUserInStorage, getUserFromStorage, updateUserInfoInStorage, updateUserAuthInStorage,
} from '../../../services/userStorage';
import { getSession, getUser, logoutUser } from '../../../services/login';
import {
  getTestPatientsEnabled, getAdministrationFeatures, getShortUsers,
} from '../../../services/administration';
import { refreshToken } from '../../../services/amazon-cognito';
import { isObjectsEqual } from '../../../services/helpers';
import { getTenantTimezone, getTenantTags } from '../../../services/tenants';
import { getPatientEventTypes } from '../../../services/patient';
// Views
import User from './User';
import QaList from './QaList';
import Patient from './Patient';
import Reports from './Reports';
import FlightPlan from './FlightPlan';
import SimplePesView from './SimplePesView';
import CountDownTimer from './CountDownTimer';
import PatientList from '../../patients/PatientList';
import UpdatedPatientMessage from '../UpdatedPatientMessage';
// Components
import { withRouter } from '../../shared/WithRouter';
import { withKeycloak } from '../../shared/WithKeycloak';
import ProtectedRoute from './ProtectedRoute';
// Constants
import {
  LOGOUT_TIMEOUT, COUNTDOWN_TIMEOUT, NOTIFICATION_TYPE,
  USER_ROLES, USER_STATUS, LOCAL_STORAGE_TIMER_KEY, AUTH_PROVIDERS,
} from '../../../constants/constants';

export class CareNavigator extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showIdleAlert: false,
      isUserLoggedIn: false,
      idleTimeOut: LOGOUT_TIMEOUT,
    };

    this.promises = {};
    this.idleTimer = null;

    this.checkLogout = this.checkLogout.bind(this);
    this.getSessionData = this.getSessionData.bind(this);
    this.parseSessionData = this.parseSessionData.bind(this);
    this.getTags = this.getTags.bind(this);
    this.getFeatures = this.getFeatures.bind(this);
    this.getEventsTypes = this.getEventsTypes.bind(this);
    this.getReadOnlyTenant = this.getReadOnlyTenant.bind(this);
  }

  componentDidMount() {
    this.setDefaultParams();
  }

  componentWillUnmount() {
    Object.values(this.promises).forEach((promise) => {
      try {
        promise.cancel();
      } catch (e) {}
    });
  }

  getEventsTypes() {
    const { showNotification, setPatientUtils } = this.props;
    const promiseName = 'getEventTypes';
    const getEventTypesRequest = getPatientEventTypes();
    const getEventTypesPromise = getEventTypesRequest.promise;
    this.promises[promiseName] = getEventTypesRequest;

    getEventTypesPromise.then((data) => {
      delete this.promises[promiseName];
      setPatientUtils({ patientEventTypes: data });
    }).catch((error) => {
      delete this.promises[promiseName];
      if (error.status === 401 || error.status === 403 || error.isCanceled) {
        return;
      }
      showNotification({
        message: 'Could not load patient event types.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  }

  getTags() {
    const { showNotification, setTenantTags } = this.props;
    const promiseName = 'getTenantTags';
    const getTenantTagsRequest = getTenantTags();
    const getTenantTagsPromise = getTenantTagsRequest.promise;
    this.promises[promiseName] = getTenantTagsRequest;

    getTenantTagsPromise.then((data) => {
      delete this.promises[promiseName];
      setTenantTags(data);
    }).catch((error) => {
      delete this.promises[promiseName];
      if (error.status === 401 || error.status === 403 || error.isCanceled) {
        return;
      }
      showNotification({
        message: 'Could not load tenants tags data.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  }

  getFeatures() {
    const { showNotification, setTenantFeatures } = this.props;
    const promiseName = 'getAdministrationFeatures';
    const getAdministrationFeaturesRequest = getAdministrationFeatures();
    const getAdministrationFeaturesPromise = getAdministrationFeaturesRequest.promise;
    this.promises[promiseName] = getAdministrationFeaturesRequest;

    getAdministrationFeaturesPromise.then((data) => {
      delete this.promises[promiseName];
      setTenantFeatures(data);
    }).catch((error) => {
      delete this.promises[promiseName];
      if (error.status === 401 || error.status === 403 || error.isCanceled) {
        return;
      }
      showNotification({
        message: 'Could not load tenants features data.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  }

  getSessionData() {
    const { loadEmptySession, showNotification, user } = this.props;

    const promiseName = 'getSession';
    const getSessionRequest = getSession();
    const getSessionPromise = getSessionRequest.promise;
    this.promises[promiseName] = getSessionRequest;

    getSessionPromise.then((data) => {
      delete this.promises[promiseName];

      if (data) {
        this.parseSessionData(data);
      } else if (user.role === USER_ROLES.CN || user.role === USER_ROLES.PES) {
        loadEmptySession(user.id);
      } else {
        loadEmptySession();
      }
    }).catch((error) => {
      delete this.promises[promiseName];

      if (error.status === 401 || error.status === 403 || error.isCanceled) {
        return;
      }

      loadEmptySession();
      showNotification({
        message: 'Could not load session data.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  }

  getTenantTimezone = () => {
    const { setTenantTimezone, showNotification } = this.props;

    const promiseName = 'getTenantTimezone';
    const getTenantTimezoneRequest = getTenantTimezone();
    const getTenantTimezonePromise = getTenantTimezoneRequest.promise;
    this.promises[promiseName] = getTenantTimezoneRequest;

    getTenantTimezonePromise.then((timezone) => {
      delete this.promises[promiseName];

      if (timezone) {
        setTenantTimezone(timezone.value);
      }
    }).catch((error) => {
      delete this.promises[promiseName];

      if (error.status === 401 || error.status === 403 || error.isCanceled) {
        return;
      }

      showNotification({
        message: 'Could not load tenant timezone.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  getUsersByRole = () => {
    const { setCnList, setPesList, showNotification } = this.props;

    const getCnUsersRequest = getShortUsers(USER_ROLES.CN, USER_STATUS.ACTIVE);
    const getCnUsersPromise = getCnUsersRequest.promise;
    const getPesUsersRequest = getShortUsers(USER_ROLES.PES, USER_STATUS.ACTIVE);
    const getPesUsersPromise = getPesUsersRequest.promise;

    getCnUsersPromise.then((data) => {
      delete getCnUsersRequest.promise;
      if (data && data.users) setCnList(data.users.length > 0 ? data.users : []);
    }).catch((error) => {
      delete getCnUsersRequest.promise;
      if (error.status === 401 || error.status === 403) {
        return;
      }
      showNotification({
        message: 'Could not load Care Navigators list.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });

    getPesUsersPromise.then((data) => {
      delete getPesUsersRequest.promise;
      if (data && data.users) setPesList(data.users.length > 0 ? data.users : []);
    }).catch((error) => {
      delete getPesUsersRequest.promise;
      if (error.status === 401 || error.status === 403) {
        return;
      }
      showNotification({
        message: 'Could not load PES list.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  getCurrentUserData = () => {
    const {
      user: { progressMap, external }, params: { tenant: tenantUrl },
      showNotification, updateGoals, updateUserStatus,
    } = this.props;

    const promiseName = 'getUser';
    const getUserRequest = getUser();
    const getUserPromise = getUserRequest.promise;

    this.promises[promiseName] = getUserPromise;

    getUserPromise.then((data) => {
      const newProgressMap = data.progressMap;
      delete this.promises[promiseName];

      if (!isObjectsEqual(progressMap, newProgressMap)) {
        updateGoals(newProgressMap);
        updateUserInfoInStorage({ progressMap: newProgressMap }, tenantUrl);
      }

      if (external !== data.external) {
        updateUserStatus(data.external);
        updateUserInfoInStorage({ external: data.external }, tenantUrl);
      }
    }).catch((error) => {
      delete this.promises[promiseName];

      if (error.status === 401 || error.status === 403 || error.isCanceled) {
        return;
      }

      showNotification({
        message: 'Could not load Care Navigators list.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  setDefaultParams() {
    this.checkUserLogin().then(() => {
      const {
        user, setDefaultQaSearchParams,
        setBillingPhysiciansList, setDefaultSearchParams, setRoles,
      } = this.props;

      this.lastAction = Date.now();

      setRoles(USER_ROLES);
      setBillingPhysiciansList();
      setDefaultQaSearchParams();

      const defaultSearchParams = {};
      if (user.role !== USER_ROLES.ADMIN) {
        defaultSearchParams.careNavigatorId = user.id;
      }
      setDefaultSearchParams(defaultSearchParams);

      this.getSessionData();
      this.getCurrentUserData();
      this.getTenantTimezone();
      this.getReadOnlyTenant();

      if (user.role !== USER_ROLES.CUSTOMER) {
        this.getUsersByRole();
        this.checkServiceEnabled();
        this.getAnniversary();
        this.getTags();
        this.getFeatures();
        this.getEventsTypes();
      }
    }).catch(() => {
    });
  }

  getReadOnlyTenant() {
    const { showBanner, isReadOnly } = this.props;

    if (isReadOnly) {
      showBanner({
        message: 'Read Only',
        canHide: false,
        bannerType: NOTIFICATION_TYPE.ERROR,
      });
    }
  }

  validateToken = () => {
    const {
      keycloak, navigate, params: { tenant: tenantUrl },
      token, updateUserAuthInRedux, showNotification,
    } = this.props;

    if (token) {
      const expirationDate = moment.unix(token.exp);
      const expirationCountdown = expirationDate.diff(moment(), 'seconds');

      // Only validate when token is within the last 14:59 minutes
      if (expirationCountdown < 900) {
        const user = getUserFromStorage(tenantUrl);

        if (user && user.authData.loggedInWith === AUTH_PROVIDERS.KEYCLOAK) {
          if (keycloak.isTokenExpired(900)) {
            keycloak.updateToken(900).then((tokenRefreshed) => {
              if (tokenRefreshed) {
                const authData = {
                  idToken: {
                    jwtToken: keycloak.idToken,
                    exp: keycloak.idTokenParsed.exp,
                  },
                  accessToken: {
                    jwtToken: keycloak.token,
                    exp: keycloak.tokenParsed.exp,
                  },
                  refreshToken: {
                    jwtToken: keycloak.refreshToken,
                  },
                  loggedInWith: AUTH_PROVIDERS.KEYCLOAK,
                };
                updateUserAuthInRedux(authData);
                updateUserAuthInStorage(authData, tenantUrl);
              }
            }).catch(() => {
              navigate(`/${tenantUrl}/expired`);
              showNotification({
                message: 'Failed to refresh the token, or the session has expired.',
                autoHide: true,
                notificationType: NOTIFICATION_TYPE.ERROR,
              });
            });
          }
        } else if (user && user.authData.loggedInWith === AUTH_PROVIDERS.COGNITO) {
          refreshToken().then(
            (refreshSession) => {
              const authData = {
                idToken: {
                  jwtToken: refreshSession.idToken.jwtToken,
                  exp: refreshSession.idToken.payload.exp,
                },
                accessToken: {
                  jwtToken: refreshSession.accessToken.jwtToken,
                  exp: refreshSession.accessToken.payload.exp,
                },
                refreshToken: {
                  jwtToken: refreshSession.refreshToken.token,
                },
                loggedInWith: AUTH_PROVIDERS.COGNITO,
              };
              updateUserAuthInRedux(authData);
              updateUserAuthInStorage(authData, tenantUrl);
            },
          );
        }
      }
    }
  }

  renewTimers = () => {
    this.validateToken();
    this.setState(prevState => ({
      ...prevState,
      showIdleAlert: false,
      idleTimeOut: LOGOUT_TIMEOUT,
    }), () => this.idleTimer.reset());
  }

  checkServiceEnabled = () => {
    const { setIsDemoEnv, showNotification } = this.props;

    const promiseName = 'getTestPatientsEnabled';
    const getTestPatientsEnabledRequest = getTestPatientsEnabled();
    const getTestPatientsEnabledPromise = getTestPatientsEnabledRequest.promise;
    this.promises[promiseName] = getTestPatientsEnabledRequest;

    getTestPatientsEnabledPromise.then((data) => {
      delete this.promises[promiseName];

      setIsDemoEnv(data);
    }).catch((error) => {
      delete this.promises[promiseName];

      if (error.status === 401 || error.status === 403 || error.isCanceled) {
        return;
      }

      showNotification({
        message: 'An error has occurred while attempting to check service.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  getAnniversary = () => {
    const { user, updateAnniversary } = this.props;

    if (user.startDate && !user.isAnniversary) {
      const userDate = moment(user.startDate);
      const currentDate = moment();
      const sameDates = userDate.date() === currentDate.date()
        && userDate.month() === currentDate.month();

      if (sameDates && currentDate.diff(userDate, 'days') > 364) {
        updateAnniversary(true);
      }
    }
  };

  checkLogout() {
    if (!this.state.showIdleAlert) {
      this.setState({
        showIdleAlert: true,
        idleTimeOut: COUNTDOWN_TIMEOUT,
      });
    } else {
      const {
        isTimer, timer, showNotification, navigate,
        params: { tenant: tenantUrl }, unBlockRouteTransitions,
      } = this.props;

      if (isTimer && timer) {
        localStorage.setItem(LOCAL_STORAGE_TIMER_KEY, timer);
        unBlockRouteTransitions();
      }

      navigate(`/${tenantUrl}/expired`);

      showNotification({
        message: 'You have been logged out due to inactivity.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    }
  }

  parseSessionData(data) {
    const { loadSession, user } = this.props;
    const sessionData = _cloneDeep(data);
    let sort = {};
    if (sessionData.sortOptions && sessionData.sortOptions.sortedBy) {
      sort = {
        key: sessionData.sortOptions.sortedBy,
        reverse: sessionData.sortOptions.order === 'DESC',
      };
    }
    if (user.role === USER_ROLES.CN || user.role === USER_ROLES.PES) {
      /* care navigators with session but without careNavigator filter */
      if (sessionData.filterOptions && !sessionData.filterOptions.careNavigatorId) {
        sessionData.filterOptions.careNavigatorId = user.id;
      }
      /* first join of care navigators */
      if (!sessionData.filterOptions) {
        const searchParams = {
          careNavigatorId: user.id,
        };
        sessionData.filterOptions = searchParams;
      }

      /**
       * From backend comes the careNavigatorId attribute inside the quickFilters
       * object. In frontend it is not necessary to have this attribute because it
       * already exists from the filterOptions object.
       */
      if (sessionData.filterOptions && sessionData.filterOptions.quickFilters) {
        delete sessionData.filterOptions.quickFilters.careNavigatorId;
      }
    }
    loadSession(
      sessionData.filterOptions, sessionData.patientQueuePageNum,
      sessionData.patientQueuePageSize, sort,
    );
  }

  requireLogin() {
    return new Promise((resolve, reject) => {
      const { user: { authData = null } = {} } = this.props;
      if (authData) {
        return resolve();
      }

      return reject();
    });
  }

  checkUser() {
    return new Promise((resolve, reject) => {
      const { keycloak, params: { tenant: tenantUrl } } = this.props;

      if (!checkUserInStorage(tenantUrl)) {
        logoutUser(keycloak);

        return reject();
      }

      return resolve();
    });
  }

  checkUserLogin() {
    return this.checkUser().then(() => this.requireLogin()).then(() => {
      this.setState({
        isUserLoggedIn: true,
      });
      return Promise.resolve();
    }).catch(() => {
      const { showNotification, navigate, params: { tenant: tenantUrl } } = this.props;

      showNotification({
        message: 'Please log in to visit this page.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
      navigate(`/${tenantUrl}`);

      return Promise.reject();
    });
  }

  render() {
    const { isSessionLoaded, user } = this.props;
    const isAdmin = user.role === USER_ROLES.ADMIN;
    const isAdminOrCustomer = user.role === USER_ROLES.ADMIN || user.role === USER_ROLES.CUSTOMER;

    if (!this.state.isUserLoggedIn || !isSessionLoaded) {
      return null;
    }

    return (
      <Fragment>
        <Routes>
          <Route
            path="list"
            element={<PatientList getUsersByRole={this.getUsersByRole} />}
          />
          <Route
            path="flight-plan/*"
            element={<FlightPlan />}
          />
          <Route
            path="qa-control/*"
            element={(
              <ProtectedRoute allowed={isAdmin}>
                <QaList />
              </ProtectedRoute>)}
          />
          <Route
            path="reports/*"
            element={(
              <ProtectedRoute allowed={isAdminOrCustomer}>
                <Reports />
              </ProtectedRoute>)}
          />
          <Route
            path="patient/:id/*"
            element={<Patient />}
          />
          <Route
            path="summary-patient/:id/*"
            element={<SimplePesView />}
          />
          <Route
            path="updated-patient-message/*"
            element={<UpdatedPatientMessage />}
          />
          <Route
            path="*"
            element={<User />}
          />
          <Route
            path="/"
            element={<Navigate to="list" />}
          />
        </Routes>
        <IdleTimer
          ref={(ref) => { this.idleTimer = ref; }}
          timeout={this.state.idleTimeOut}
          onIdle={this.checkLogout}
          onAction={this.validateToken}
          debounce={200}
        />
        <div className="ccm-notification-container">
          <Alert show={this.state.showIdleAlert} variant="ccm-yellow">
            <div className="d-flex-center">
              <i className="bi bi-bell-fill mr-2" />
              <span className="mr-2">Are you still there?</span>
              <CountDownTimer onAction={this.checkLogout} />
              <Button className="ml-auto" onClick={this.renewTimers} variant="ccm-yellow">
                Yes
              </Button>
            </div>
          </Alert>
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const accessToken = state.user && state.user.authData && state.user.authData.accessToken
    ? state.user.authData.accessToken
    : null;

  return {
    token: accessToken,
    user: state.user,
    isSessionLoaded: state.search.isSessionLoaded,
    timer: state.patient && state.patient.timer,
    isTimer: state.router.elements && state.router.elements.isTimer,
    isReadOnly: state.tenant && state.tenant.isReadOnly,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    setPatientUtils: utils => dispatch(UpdatePatientUtils(utils)),
    setTenantTags: tags => dispatch(SetTenantTags(tags)),
    setTenantFeatures: features => dispatch(SetTenantFeatures(features)),
    loadEmptySession: () => dispatch(LoadEmptySession()),
    loadSession: sessionData => dispatch(LoadSession(sessionData)),
    setBillingPhysiciansList: billingPhysiciansListData => dispatch(
      SetBillingPhysiciansList(billingPhysiciansListData),
    ),
    setCnList: cnListData => dispatch(SetCnList(cnListData)),
    setPesList: pesListData => dispatch(SetPesList(pesListData)),
    setDefaultSearchParams: defaultSearchData => dispatch(
      SetDefaultSearchParams(defaultSearchData),
    ),
    setDefaultQaSearchParams: defaultSearchData => dispatch(
      SetDefaultQaSearchParams(defaultSearchData),
    ),
    setRoles: rolesData => dispatch(SetRoles(rolesData)),
    showNotification: notificationData => dispatch(ShowNotification(notificationData)),
    updateGoals: value => dispatch(UpdateUserGoals(value)),
    updateUserAuthInRedux: value => dispatch(UpdateUserAuth(value)),
    setTenantTimezone: timezone => dispatch(SetTenantTimezone(timezone)),
    setIsDemoEnv: value => dispatch(SetIsDemoEnv(value)),
    updateUserStatus: userData => dispatch(UpdateUserStatus(userData)),
    updateAnniversary: value => dispatch(UpdateAnniversary(value)),
    unBlockRouteTransitions: value => dispatch(UnBlockRouteTransitions(value)),
    showBanner: value => dispatch(ShowBanner(value)),
  };
}

export default withKeycloak(
  withRouter(
    connect(mapStateToProps, mapDispatchToProps)(CareNavigator),
  ),
);
