// Libraries
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
// Actions
import ShowNotification, { HideNotification } from '../../actions/notification';
import { UpdatePatient, UpdatePatientHistory, UpdatePatientCarePlan } from '../../actions/patient';
import { SetConsentUrl } from '../../actions/tenants';
// Constants
import {
  CARE_PLAN_DEFAULTS as initialState, NOTIFICATION_TYPE, USER_ROLES,
} from '../../constants/constants';
// Services
import { downloadFile } from '../../services/helpers';
import {
  createCarePlanPdf, getCarePlan, getPatientHistory,
} from '../../services/patient';
import { getConsentUrl } from '../../services/administration';
// Views
import Vitals from './carePlan/vitals/Vitals';
import Interventions from './carePlan/interventions/Interventions';
import Goals from './carePlan/goals/Goals';
import Consent from './carePlan/consent/Consent';
import Screening from './carePlan/screening/Screening';
import AdditionalInformation from './carePlan/additionalInformation/AdditionalInformation';
// Components
import { withRouter } from '../shared/WithRouter';


export class CarePlan extends Component {
  constructor(props) {
    super(props);

    this.state = initialState;

    this.promises = {};

    this.printCarePlan = this.printCarePlan.bind(this);
    this.loadPatientData = this.loadPatientData.bind(this);
  }

  componentDidMount() {
    const { hideNotification } = this.props;
    hideNotification();

    this.loadPatientData();
    this.loadPatientHistory();
    this.loadPatientConsentUrl();
  }

  printCarePlan() {
    const { hideNotification, showNotification, params: { id: patientId } } = this.props;

    hideNotification();


    const promiseName = 'createCarePlanPdf';
    const createCarePlanPdfRequest = createCarePlanPdf(patientId);
    const createCarePlanPdfPromise = createCarePlanPdfRequest.promise;
    this.promises[promiseName] = createCarePlanPdfRequest;

    createCarePlanPdfPromise.then((data) => {
      delete this.promises[promiseName];

      downloadFile(data.data, data.fileName);
    }).catch((error) => {
      if (error.isCanceled) {
        return;
      }

      delete this.promises[promiseName];

      if (error.status === 401 || error.status === 403) {
        return;
      }

      showNotification({
        message: 'Could not generate PDF, please try again later',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  }

  async loadPatientData(shouldPatientBeUpdated) {
    const {
      showNotification, updatePatient, updatePatientCarePlan,
      navigate, params: { tenant: tenantUrl, id: patientId },
    } = this.props;

    const promiseNames = [];
    const getCarePlanRequest = getCarePlan(patientId);
    const getCarePlanPromise = getCarePlanRequest.promise;
    this.promises[promiseNames[0]] = getCarePlanRequest;

    const promises = [getCarePlanPromise];

    try {
      this.setState({ isCarePlanLoaded: true });

      const [{
        carePlan, patient,
        carePlan: { resolvedInterventions = [], unresolvedInterventions = [] },
      }] = await Promise.all(promises);

      let newPatientInfo = {
        hasGoals: carePlan.goals && carePlan.goals.length,
        hasInterventions: resolvedInterventions.length || unresolvedInterventions.length,
      };

      if (shouldPatientBeUpdated && patient) {
        newPatientInfo = { ...patient, ...newPatientInfo };
      }

      const allInterventionsSorted = [
        ...resolvedInterventions,
        ...unresolvedInterventions,
      ].sort((a, b) => b.id - a.id);

      const newCarePlan = {
        ...carePlan,
        interventions: allInterventionsSorted,
      };

      delete newCarePlan.resolvedInterventions;
      delete newCarePlan.unresolvedInterventions;
      promiseNames.forEach(promiseName => delete this.promises[promiseName]);

      await updatePatient(newPatientInfo);
      await updatePatientCarePlan(newCarePlan);
    } catch (error) {
      if (error.isCanceled) {
        return;
      }

      promiseNames.forEach((promiseName) => {
        delete this.promises[promiseName];
      });

      if (error.status === 401 || error.status === 403) {
        return;
      }

      navigate(`/${tenantUrl}/cn/list`);

      showNotification({
        message: 'Could not load patient data',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    }
  }

  // TODO: move vitals information to care plan response
  loadPatientHistory() {
    const { hideNotification, showNotification, updatePatientHistory } = this.props;
    const { navigate, params: { tenant: tenantUrl, id: patientId } } = this.props;

    hideNotification();

    const getPatientHistoryRequest = getPatientHistory(patientId);
    const getPatientHistoryPromise = getPatientHistoryRequest.promise;

    getPatientHistoryPromise.then((data) => {
      updatePatientHistory(data);
    }).catch((error) => {
      if (error.isCanceled) {
        return;
      }
      if (error.status === 401 || error.status === 403) {
        return;
      }

      navigate(`/${tenantUrl}/cn/list`);

      showNotification({
        message: 'Could not load patient vitals',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  }

  loadPatientConsentUrl() {
    const { showNotification, setConsentUrl } = this.props;

    const getConsentUrlRequest = getConsentUrl();
    const getConsentUrlPromise = getConsentUrlRequest.promise;

    getConsentUrlPromise.then((data) => {
      if (data) setConsentUrl(data.consentScriptLink);
    }).catch((error) => {
      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }
      showNotification({
        message: 'Could not load consent url',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  }

  renderCarePlanActionButtons = () => {
    const {
      userRole, patient: {
        carePlan: {
          carePlanId,
        } = {},
      } = {},
    } = this.props;
    const isAdmin = userRole && userRole === USER_ROLES.ADMIN;

    let printButton;

    if (carePlanId) {
      printButton = isAdmin && (
        <Button onClick={this.printCarePlan} data-test="printCarePlanButton" className="ml-2">Print</Button>
      );
    }

    return (
      <div>
        <div className="d-flex justify-content-end">
          {printButton}
        </div>
      </div>
    );
  }

  render() {
    if (!this.state.isCarePlanLoaded) {
      return null;
    }

    const { loading, params: { id: patientId }, isReadOnly = false } = this.props;

    return (
      <div className={`patient-care-plan h-100 overflow-auto pr-3 ${loading ? ' d-none' : ''}`}>
        <div className="d-flex-center-between my-3">
          <h4 className="text-uppercase text-left mb-0">Care Plan</h4>
          {this.renderCarePlanActionButtons()}
        </div>
        <Vitals patientId={patientId} isReadOnly={isReadOnly} />
        <Screening
          patientId={patientId}
          loadPatientData={this.loadPatientData}
          isReadOnly={isReadOnly}
        />
        <Goals patientId={patientId} isReadOnly={isReadOnly} />
        <Interventions patientId={patientId} isReadOnly={isReadOnly} />
        <AdditionalInformation patientId={patientId} isReadOnly={isReadOnly} />
        <Consent
          patientId={patientId}
          loadPatientData={this.loadPatientData}
          isReadOnly={isReadOnly}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    patient: state.patient,
    loading: state.requestsInProgress.count,
    userRole: state.user && (state.user.role || ''),
    timezone: state.tenant && state.tenant.timezone,
    isReadOnly: state.tenant && state.tenant.isReadOnly,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    hideNotification: () => dispatch(HideNotification()),
    showNotification: notificationData => dispatch(ShowNotification(notificationData)),
    updatePatient: patientData => dispatch(UpdatePatient(patientData)),
    updatePatientHistory: patientHistoryData => dispatch(UpdatePatientHistory(patientHistoryData)),
    updatePatientCarePlan: carePlanData => dispatch(UpdatePatientCarePlan(carePlanData)),
    setConsentUrl: data => dispatch(SetConsentUrl(data)),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CarePlan));
