export default function ShowBanner(params) {
  return {
    type: 'SHOW_BANNER',
    canHide: params.canHide,
    message: params.message,
    bannerType: params.bannerType,
  };
}

export function HideBanner() {
  return {
    type: 'HIDE_BANNER',
  };
}
