// Libraries
import React, { useContext, useState, useEffect } from 'react';
import moment from 'moment';
import _orderBy from 'lodash/orderBy';
import { useDispatch, useSelector } from 'react-redux';
import {
  Accordion, Card, Button, Dropdown,
} from 'react-bootstrap';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
// Actions
import ShowNotification from '../../../../actions/notification';
// Services
import { deletePatientAdditionalInfo } from '../../../../services/patient';
// Constants
import {
  NOTIFICATION_TYPE, EMPTY_STRING, DATE_FORMAT,
  USER_ROLES,
} from '../../../../constants/constants';
// Hooks
import useLoadPatientAdditionalInfo from '../../../../hooks/services/useLoadPatientAdditionalInfo';
// Views
import AdditionalInformationModal from './AdditionalInformationModal';
// Local Constants
const DEFAULT_YEAR = moment.utc().format(DATE_FORMAT.YEAR);

export const AdditionalInformation = (props) => {
  const { patientId, isReadOnly = false } = props;
  const { user } = useSelector(state => state);
  const dispatch = useDispatch();
  const showNotification = data => dispatch(ShowNotification(data));
  const INFORMATION_FILTERS = { [DEFAULT_YEAR]: { name: DEFAULT_YEAR } };
  const isAdminUser = user.role === USER_ROLES.ADMIN;

  const { additionalInfo, refetch } = useLoadPatientAdditionalInfo({ patientId });

  if (additionalInfo.length > 0) {
    additionalInfo.forEach((information) => {
      const year = information.createdAt.split('-')[0];
      INFORMATION_FILTERS[year] = { name: year };
    });
  }

  const [collapseAll, setCollapseAll] = useState(true);
  const [informationFilter, setInformationFilter] = useState(DEFAULT_YEAR);
  const [selectedInformation, setSelectedInformation] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [informationList, setInformationList] = useState(additionalInfo);

  const handleAddEditInformation = (information) => {
    setSelectedInformation(information);
    setIsModalOpen(true);
  };

  const handleDeleteInformation = (information) => {
    if (!window.confirm('Are you sure you want to delete this additional information?')) {
      return;
    }

    const deleteAdditionalInfoRequest = deletePatientAdditionalInfo(patientId, information.id);
    const deleteAdditionalInfoPromise = deleteAdditionalInfoRequest.promise;

    deleteAdditionalInfoPromise.then(() => {
      delete deleteAdditionalInfoRequest.promise;
      if (refetch) refetch();
    }).catch((error) => {
      delete deleteAdditionalInfoRequest.promise;
      if (error.isCanceled) {
        return;
      }
      showNotification({
        message: 'Could not delete patient information, please try again later',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  const renderDropDownItems = Object.keys(INFORMATION_FILTERS).map((key, index) => {
    const yearName = DEFAULT_YEAR === key ? 'This Year' : key;
    return (
      <Dropdown.Item
        key={`additionalInformation__filter-${index}`}
        as="button"
        className="d-flex my-1"
        onClick={() => setInformationFilter(INFORMATION_FILTERS[key].name)}
        data-test="additionalInformation_filterItem"
      >
        <div className="pr-3">{yearName}</div>
      </Dropdown.Item>
    );
  });

  function renderEmptyRow() {
    return (
      <tr data-test="additionalInformation_emptyMsg">
        <td colSpan="7" className="p-2 border-0">
          No additional information have been defined for this patient.
        </td>
      </tr>
    );
  }

  const renderRows = () => {
    const renderInformation = informationList.filter((information) => {
      const year = information.createdAt.split('-')[0];
      return year === informationFilter;
    });

    if (renderInformation && !!renderInformation.length) {
      const sortedInfoByDate = _orderBy(renderInformation, [el => moment(el.createdAt), 'id'], ['desc', 'desc']);
      return sortedInfoByDate.map((information) => {
        const getDateString = date => moment
          .utc(date).format(DATE_FORMAT.FULL_WITH_TIME_IN_12_HOURS);

        const isAbleToEdit = isAdminUser
          || (information && information.createdBy && user.id === information.createdBy.id);

        const onClickAction = () => (isAbleToEdit ? handleAddEditInformation(information) : {});

        return (
          <tr key={`information__row-${information.id}`} data-test="additionalInformation_entryRow">
            <td colSpan="7" className="p-0">
              <Accordion defaultActiveKey="0">
                <Card className="border-0 rounded-0">
                  <Card.Header className="bg-light border-0 p-0">
                    <table className="table mb-0 table-hover">
                      <tbody>
                        <tr className={`${isAbleToEdit ? '' : 'no-editable'}`}>
                          <td className="information__createdDate" onClick={onClickAction} data-test="additionalInformation_editBtn">
                            {information.createdAt && getDateString(information.createdAt)}
                          </td>
                          <td className="information__createdBy" onClick={onClickAction} data-test="additionalInformation_editBtn">
                            {information.createdBy ? `${information.createdBy.firstName} ${information.createdBy.lastName}` : EMPTY_STRING}
                          </td>
                          <td className="information__note" onClick={onClickAction} data-test="additionalInformation_editBtn">
                            <div className={`${collapseAll ? 'truncate-text' : ''}`}>
                              {information.note || EMPTY_STRING}
                            </div>
                          </td>
                          <td className="information__solve px-0">
                            <Button variant="link-dark" className="ml-2 px-0" onClick={() => handleDeleteInformation(information)} disabled={isReadOnly || !isAbleToEdit} data-test="additionalInformation_deleteBtn">
                              <i className="bi-trash" />
                            </Button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Card.Header>
                </Card>
              </Accordion>
            </td>
          </tr>
        );
      });
    }
    return renderEmptyRow();
  };

  useEffect(() => setInformationList(additionalInfo), [additionalInfo]);

  const renderTableHeader = (attribute, columnName) => (
    <th className={`py-2 information__${attribute}`} data-test={`additionalInformation_${attribute}Order`}>
      {columnName}
    </th>
  );

  return (
    <div className="care-plan-additional-info card border-0">
      <div className="card-header rounded-0 bg-ccm-light-gray border text-ccm-bismark d-flex-center py-1">
        <Dropdown
          className="position-absolute"
          style={{ left: 1 }}
          data-test="additionalInformation_filter"
        >
          <Dropdown.Toggle
            variant="ccm-light-gray"
            className="py-0"
          >
            {`Filter: ${DEFAULT_YEAR === informationFilter ? 'This Year' : informationFilter}`}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {renderDropDownItems}
          </Dropdown.Menu>
        </Dropdown>
        <span className="text-uppercase">Additional Information</span>
        <Button
          size="sm"
          variant="link-dark"
          className="d-flex-center position-absolute"
          style={{ right: '3rem' }}
          onClick={() => setCollapseAll(!collapseAll)}
          data-test="additionalInformation_collapseAllBtn"
        >
          <span>{`${collapseAll ? 'Expand' : 'Collapse'} all`}</span>
          <i className={`d-flex-center bi-caret-${collapseAll ? 'down' : 'up'}-fill ml-1`} />
        </Button>
        <Button
          size="sm"
          variant="link-dark"
          className="position-absolute"
          style={{ right: 10 }}
          onClick={() => handleAddEditInformation(null)}
          data-test="additionalInformation_addBtn"
          disabled
        >
          <i className="d-flex-center bi-plus-lg" />
        </Button>
      </div>
      <div className="card-body ccm-table-container px-0">
        <table className="table w-100 text-left">
          <thead>
            <tr>
              {renderTableHeader('createdAt', 'Date')}
              {renderTableHeader('createdBy', 'Created By')}
              {renderTableHeader('note', 'Note')}
              <th className="py-2 information__solve px-0" />
            </tr>
          </thead>
          <tbody>
            {renderRows()}
          </tbody>
        </table>
      </div>
      <AdditionalInformationModal
        patientId={patientId}
        initialInformation={selectedInformation}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        callbackAction={refetch}
      />
    </div>
  );
};

export const CustomToggle = ({ eventKey, collapseAll }) => {
  const currentEventKey = useContext(AccordionContext);
  const isCurrentEventKey = currentEventKey === eventKey;
  const decoratedOnClick = useAccordionToggle(eventKey, () => {});

  useEffect(() => {
    if ((collapseAll && isCurrentEventKey) || (!collapseAll && !isCurrentEventKey)) {
      decoratedOnClick();
    }
  }, [collapseAll]);

  return (
    <Button variant="link-dark" className="p-0" onClick={decoratedOnClick}>
      <i className={`d-flex-center bi-caret-${isCurrentEventKey ? 'up' : 'down'}-fill`} />
    </Button>
  );
};

export default AdditionalInformation;
