// Libraries
import React, {
  useEffect, useState, Fragment, Suspense, lazy,
} from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
// Actions
import ShowNotification from '../../actions/notification';
import { BlockRouteTransitions, UnBlockRouteTransitions } from '../../actions/router';
// Services
import { savePatientPrompts } from '../../services/patient';
// Custom Hooks
import useLoadPrompts from '../../hooks/services/useLoadPrompts';
// Constants
import { DATE_FORMAT, NOTIFICATION_TYPE, USER_ROLES } from '../../constants/constants';
import {
  DEFAULT_PROMPTS, PROMPT_OPTION_LABEL, PROMPT_CODES, getPromptOptions, PROMPTS_SUCCESS_MESSAGES,
} from '../../constants/prompts';
// Lazy
const AlertModal = lazy(() => import('./prompts/AlertModal'));
// Local Constants
const ELEMENT_NAME_TO_BLOCK = 'isWorkingOnPrompts';
const DISABLED_EDIT_BUTTON = false;


export const Prompts = ({ reloadData }) => {
  const { id: patientId } = useParams();
  const {
    user: { role } = {},
    tenant: { isReadOnly = false } = {},
  } = useSelector(state => state);
  const { prompts, refetch: refetchPrompts } = useLoadPrompts({ patientId });

  const isCnUser = role === USER_ROLES.CN;
  const isAdminUser = role === USER_ROLES.ADMIN;

  const dispatch = useDispatch();
  const showNotification = data => dispatch(ShowNotification(data));
  const blockTransitions = (elem, value) => dispatch(BlockRouteTransitions(elem, value));
  const unblockTransitions = elem => dispatch(UnBlockRouteTransitions(elem));

  const [editMode, setEditMode] = useState(false);
  const [promptsObject, setPromptsObject] = useState(null);
  const [optionsSelected, setOptionsSelected] = useState(0);
  const [optionsAvailable, setOptionsAvailable] = useState(0);
  const [promptsActions, setPromptsActions] = useState(new Map());
  const [isAlertModalOpen, setIsAlertModalOpen] = useState(false);

  const getPrompts = () => {
    if (prompts.length > 0) {
      const formattedPrompts = prompts.map(promptItem => ({
        id: promptItem.id,
        category: promptItem.category,
        promptText: promptItem.promptText,
        guidanceText: promptItem.guidanceText,
        promptOptions: getPromptOptions(promptItem.confirmationType, promptItem.isRequiredTopic),
        response: promptItem.response,
      }));

      const noResponse = formattedPrompts.filter(item => item.response === null);
      const noAnsweredThisMonth = formattedPrompts
        .filter(item => item.response && !item.response.answeredThisMonth);
      setOptionsAvailable(noAnsweredThisMonth.length + noResponse.length);

      const promptsByCategory = formattedPrompts.reduce((result, current) => {
        const resultPrompts = result;
        if (!resultPrompts[current.category]) resultPrompts[current.category] = [];
        resultPrompts[current.category].push(current);
        return resultPrompts;
      }, {});

      setPromptsObject(promptsByCategory);
    }
  };

  const createActions = (promptId, actionId) => {
    const newPromptsActions = new Map(promptsActions);
    newPromptsActions.set(promptId, actionId);
    setPromptsActions(newPromptsActions);
    setOptionsSelected(newPromptsActions.size);
  };

  const savePrompts = async () => {
    const data = { actions: Object.fromEntries(promptsActions) };

    const savePatientPromptsRequest = savePatientPrompts(patientId, data);
    const savePatientPromptsPromise = savePatientPromptsRequest.promise;

    try {
      await savePatientPromptsPromise;
      delete savePatientPromptsRequest.promise;
      refetchPrompts();
      if (reloadData) reloadData();
      if (editMode) {
        setEditMode(false);
        setIsAlertModalOpen(true);
      }
      showNotification({
        message: PROMPTS_SUCCESS_MESSAGES.creation,
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.SUCCESS,
      });
    } catch (error) {
      delete savePatientPromptsRequest.promise;
      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }
      showNotification({
        message: 'An error occurred. Please try again.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    }
  };

  useEffect(() => { getPrompts(); }, [prompts]);

  useEffect(() => {
    const newPromptsActions = new Map(promptsActions);
    newPromptsActions.clear();
    setPromptsActions(newPromptsActions);
  }, []);

  useEffect(() => () => unblockTransitions(ELEMENT_NAME_TO_BLOCK), []);

  useEffect(() => {
    if (editMode && prompts.length > 0) {
      const newPromptsActions = new Map(promptsActions);
      newPromptsActions.clear();

      prompts.forEach((prompt) => {
        if (prompt.response && prompt.response.answeredThisMonth) {
          newPromptsActions.set(prompt.id, prompt.response.answer);
        }
      });

      setPromptsActions(newPromptsActions);
      setOptionsSelected(newPromptsActions.size);
    }
  }, [editMode]);

  const displayPrompts = (promptsList, prompt) => {
    if (promptsList && promptsList[prompt.id] && promptsList[prompt.id].length > 0) {
      return promptsList[prompt.id].map((info => (
        <div className="pl-3 pt-3 content" key={info.id}>
          <span data-test="prompts_PromptContentInfo" className="font-weight-bold sub-title">{info.promptText}</span>
          <div className="pl-4">
            {info.guidanceText.split('\n').map((text, indexText) => (
              <p className="text-ccm-boulder" key={indexText}>
                {text}
              </p>
            ))}
            {!editMode && info.response && info.response.answeredThisMonth ? (
              <Fragment>
                <span>Answer:&nbsp;</span>
                <span className="font-italic">
                  {`${PROMPT_OPTION_LABEL[info.response.answer]} (${moment(info.response.answeredAt).format(DATE_FORMAT.SHORT)})`}
                </span>
              </Fragment>
            ) : info.promptOptions.map(option => (
              <Form.Check
                inline
                type="radio"
                id={`checkboxId_${info.id}_${option.id}`}
                key={`checkboxKey_${info.id}_${option.id}`}
                label={option.label}
                name={`promptsOptions_${info.id}`}
                disabled={isReadOnly}
                checked={promptsActions && promptsActions.size
                  && promptsActions.size > 0 && option.id === promptsActions.get(info.id)}
                onChange={() => {
                  blockTransitions(ELEMENT_NAME_TO_BLOCK);
                  createActions(info.id, option.id);
                }}
                data-test={`prompts_checkbox-${info.id}_${option.id}`}
              />
            ))}
          </div>
        </div>
      )));
    }

    return (
      <div className="pl-3 pt-3 content">
        <span className="font-weight-bold sub-title text-capitalize">none specified</span>
      </div>
    );
  };

  const displayPromptSections = (prompt) => {
    // Do not show PARTNER section when there are no prompts
    if (prompt.id === PROMPT_CODES.partner.id && promptsObject
      && promptsObject[prompt.id] === undefined) {
      return null;
    }

    return (
      <div className="prompts-options box-wrapper m-4 p-3" key={prompt.id}>
        <div className="text-capitalize title" id={prompt.htmlId} data-test={prompt.name}>
          <span>{prompt.name}</span>
        </div>
        {displayPrompts(promptsObject, prompt)}
      </div>
    );
  };

  const displayEditButtons = () => {
    if (editMode) {
      return (
        <div className="dialog-buttons justify-content-end px-4">
          <Button
            variant="light"
            className="text-capitalize mr-4"
            onClick={() => {
              unblockTransitions(ELEMENT_NAME_TO_BLOCK);
              setEditMode(false);
            }}
            data-test="prompts_cancelBtn"
          >
            cancel
          </Button>
          <Button
            variant="primary"
            className="text-capitalize"
            onClick={() => {
              unblockTransitions(ELEMENT_NAME_TO_BLOCK);
              savePrompts();
            }}
            data-test="prompts_saveBtn"
          >
            save
          </Button>
        </div>
      );
    }

    return (
      <div className="d-flex justify-content-center m-2">
        <h5 data-test="prompts_thankYouMsg">Thank you for answering all of this month&#x2019;s prompts.</h5>
      </div>
    );
  };

  return (
    <div className="patient-prompts h-100 pr-2 overflow-auto text-left">
      <div className="d-flex-center-between pr-4">
        <h4 className="text-uppercase my-3" data-test="prompts_promptsHeader">prompts</h4>
        {(isCnUser || isAdminUser) && optionsAvailable === 0 && DISABLED_EDIT_BUTTON && (
          <Button
            variant="primary"
            className="text-capitalize"
            onClick={() => {
              blockTransitions(ELEMENT_NAME_TO_BLOCK);
              setEditMode(true);
            }}
            disabled={editMode || isReadOnly}
            data-test="prompts_editBtn"
          >
            edit
          </Button>
        )}
      </div>
      {DEFAULT_PROMPTS.map(prompt => displayPromptSections(prompt))}
      {optionsAvailable === 0 ? displayEditButtons() : (
        <div className="dialog-buttons justify-content-end px-4">
          <Button
            variant="primary"
            className="text-capitalize"
            onClick={() => {
              unblockTransitions(ELEMENT_NAME_TO_BLOCK);
              savePrompts();
            }}
            disabled={optionsSelected < optionsAvailable || isReadOnly}
            data-test="prompts_completeBtn"
          >
            complete prompts
          </Button>
        </div>
      )}
      <Suspense fallback={null}>
        {isAlertModalOpen && (
          <AlertModal
            isModalOpen={isAlertModalOpen}
            setIsModalOpen={setIsAlertModalOpen}
          />
        )}
      </Suspense>
    </div>
  );
};

export default Prompts;
