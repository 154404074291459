// Libraries
import React, { useState } from 'react';
import { useField, useFormikContext } from 'formik';
import DatePicker from 'react-datepicker';
import { Form } from 'react-bootstrap';
// Services
import { isWeekendOrHoliday, conditionalDateFormatFilter } from '../../../services/helpers';
// Custom Hooks
import useLoadHolidays from '../../../hooks/services/useLoadHolidays';

export const Datepicker = ({
  label, subtext, styles = {}, showDaysAlert, conditionalScreening = false,
  conditionalDateText, ...props
}) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(props);

  const { holidays } = useLoadHolidays();

  const isOffDay = (date, isCss = true) => isWeekendOrHoliday(date, isCss, holidays);
  const showDaysAlertCss = showDaysAlert ? { dayClassName: isOffDay } : {};

  const [errorMessage, setErrorMessage] = useState('');

  const onChange = props.onChange
    ? props.onChange : date => setFieldValue(field.name, date);

  // onChangeRaw event handler runs with conditional screening datepicker questions
  const onChangeRaw = (event) => {
    const rawValue = event.target.value;
    const parsedDate = new Date(rawValue);
    let valueRef = rawValue;

    // helper method to filter any manual input values not strictly fitting the 'MM/dd/yyyy' format
    conditionalDateFormatFilter(rawValue, setFieldValue, setErrorMessage, field);

    // disables future dates
    if (!Number.isNaN(parsedDate) && parsedDate > new Date()) {
      setFieldValue(field.name, null);
      const msg = conditionalDateText === 'Enter an admission date'
        ? `${valueRef} is in the future. Enter a valid admission date.`
        : `${valueRef} is in the future. Enter a valid discharge date.`;
      setErrorMessage(msg);
      valueRef = '';
    } else {
      setErrorMessage('');
    }
  };

  return (
    <Form.Group className={styles.formGroup}>
      <Form.Label
        htmlFor={props.id || props.name}
        className={styles.formLabel}
      >
        {label || ''}
      </Form.Label>
      <DatePicker
        className="form-control"
        aria-describedby={`${props.name} Date Picker`}
        {...showDaysAlertCss}
        {...field}
        {...props}
        selected={(field.value && new Date(field.value)) || null}
        onChange={onChange}
        {...(conditionalScreening ? {
          onChangeRaw,
          maxDate: new Date(),
          dateFormat: 'MM/dd/yyyy',
          placeholderText: 'MM/DD/YYYY',
          strictParsing: true,
        }
          : {})}
      />
      {subtext && <Form.Text muted>{subtext}</Form.Text>}
      {field.value && showDaysAlert && (
        <Form.Text className="medium-text text-ccm-red font-weight-bold">
          {isOffDay(new Date(field.value), false)}
        </Form.Text>)
      }
      {meta.error && !errorMessage && (
        <Form.Text className="text-danger" data-test="datepicker_errorMsg">{meta.error}</Form.Text>
      )
      }
      {meta.error && errorMessage && (
        <Form.Text className="text-danger" data-test="datepicker_errorMsg">{errorMessage}</Form.Text>
      )
      }
    </Form.Group>
  );
};
