// Libraries
import React, {
  Fragment, useState, lazy, Suspense,
} from 'react';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
// Components
import Reinstate from './Reinstate';
// Constants
import { PATIENT_STATUS, USER_ROLES } from '../../../constants/constants';
// Views
const BulkChangePatientStatusModal = lazy(() => import('../../patients/fixedListHeader/BulkChangePatientStatusModal'));


export const ChangePatientStatusBlock = (props) => {
  const { setChangeStatusView, reloadPatientInfo } = props;
  const { id: patientId } = useParams();
  const {
    user: { role: userRole },
    patient, patient: {
      status: patientStatus,
      permissions: { canBeChangedTo = {} } = {},
      activityStatusInfo,
    },
    tenant: { isReadOnly = false },
  } = useSelector(state => state);

  const activityStatus = activityStatusInfo?.status ?? 'ACTIVE';

  if (!patient || !patient.permissions || !patient.permissions.canBeChangedTo) {
    return null;
  }

  const [isBulkPatientStatusModal, setIsBulkPatientStatusModal] = useState(false);

  const isCnUser = userRole === USER_ROLES.CN;
  const isAdminUser = userRole === USER_ROLES.ADMIN;
  const disableButton = isReadOnly || (isAdminUser && activityStatus) === 'DECEASED';
  const differentLogic = PATIENT_STATUS.differentFlow.includes(patientStatus);
  const isPatientStatusSuspended = patientStatus === 'CS';
  const canBeChange = canBeChangedTo.some(status => status.charAt(0) === 'P' || status.charAt(0) === 'C');

  const changeStatusAction = () => {
    if (isAdminUser && differentLogic) {
      setIsBulkPatientStatusModal(true);
    } else {
      setChangeStatusView(true);
    }
  };

  return (
    <Fragment>
      <div className="summary-status-buttons">
        <p className="font-weight-bold" data-test="changePatientStatusBlock_verifyNameAndConfirmInsurance">Don&apos;t forget to verify name and confirm insurance</p>
        {(isCnUser && !differentLogic) || (isAdminUser) ? (
          <Button
            variant="primary"
            onClick={() => changeStatusAction()}
            data-test="changePatientStatusBlock_changePatientStatusBtn"
            disabled={disableButton}
          >
            Change Patient Status
          </Button>
        ) : null}
        {(isAdminUser || isCnUser) && isPatientStatusSuspended && canBeChange && <Reinstate />}
      </div>
      <Suspense fallback={null}>
        {isBulkPatientStatusModal && (
          <BulkChangePatientStatusModal
            isModalOpen={isBulkPatientStatusModal}
            setIsModalOpen={setIsBulkPatientStatusModal}
            selectedPatientsIds={new Set([Number(patientId)])}
            reloadData={reloadPatientInfo}
            textAreaLabel="Reason for status change"
          />
        )}
      </Suspense>
    </Fragment>
  );
};

export default ChangePatientStatusBlock;
