// Libraries
import React from 'react';
import { connect } from 'react-redux';

// Views
import Logo from './Logo';
import User from './User';
import QueueControl from './QueueControl';
import ReportsControl from './ReportsControl';
import FlightPlanControl from './FlightPlanControl';
import QAControl from './QAControl';
import Loading from '../base/Loading';
import Stopwatch from '../base/Stopwatch';
import AddTimeTrackingButton from './AddTimeTrackingButton';
import { USER_ROLES } from '../../constants/constants';

export function TopBar(props) {
  const { user: { role: userRole } = {} } = props;
  const isAdmin = userRole === USER_ROLES.ADMIN;
  const isCustomer = userRole === USER_ROLES.CUSTOMER;

  const renderTopBarControls = () => {
    const topBarControls = [];

    if (!isCustomer) {
      topBarControls.push(
        <QueueControl key="topBar_patientsQueue" />,
        <FlightPlanControl key="topBar_flight" />,
      );
    }

    if (isAdmin) topBarControls.push(<QAControl key="topBar_qaList" />);

    if (isAdmin || isCustomer) topBarControls.push(<ReportsControl key="topBar_reports" />);

    return topBarControls;
  };


  return (
    <nav className="navbar navbar-expand-lg top-bar mt-2">
      {renderTopBarControls()}

      <span className="navbar-brand">
        <Loading />
        <Logo />
      </span>
      <div className="d-flex-center ml-auto mr-3">
        <Stopwatch userRole={userRole} />
        <AddTimeTrackingButton />
      </div>
      <User />
    </nav>
  );
}

export function mapStateToProps(state) {
  return {
    loading: state.requestsInProgress.count > 0,
    user: state.user,
  };
}

export default connect(mapStateToProps)(TopBar);
