// libraries
import React, { Component } from 'react';
import moment from 'moment';
import InputMask from 'react-input-mask';
import ReactTooltip from 'react-tooltip';
// services
import { formatPhone, formatPhoneForRequest } from '../../services/helpers';
// views
import DatePicker from './DatePicker';
// constants
import { DATE_FORMAT, PHONE_MASK } from '../../constants/constants';

const phoneType = 'phone';

function isEmpty(value) {
  return !value && value !== 0;
}

export class EditableInput extends Component {
  constructor(props) {
    super(props);

    const isValueEmpty = isEmpty(props.initialValue);
    let value = !isValueEmpty ? props.initialValue : '';

    if (props.formatType === phoneType) {
      value = formatPhone(value);
    }

    this.state = {
      value,
      editing: isValueEmpty,
      oldValue: value,
      validation: {
        valid: true,
        validationMessage: '',
      },
    };

    this.validate = this.validate.bind(this);
    this.clearValidation = this.clearValidation.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeDate = this.handleChangeDate.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
    this.saveData = this.saveData.bind(this);
    this.renderDatePicker = this.renderDatePicker.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { initialValue, formatType } = this.props;
    const { validation: { valid } } = this.state;

    if (initialValue !== prevProps.initialValue) {
      const isValueEmpty = isEmpty(initialValue);
      let value = !isValueEmpty ? initialValue : '';

      if (formatType === phoneType) {
        value = formatPhone(value);
      }

      this.setState({
        value,
        oldValue: value,
        editing: isValueEmpty || !valid,
      });
    }
  }

  onClick() {
    if (this.props.disabled) {
      return;
    }

    this.setState({
      editing: true,
    });
  }

  onBlur() {
    this.saveData();
  }

  onFocus(event) {
    if (!event || !event.target) {
      return;
    }

    const input = event.target;
    const inputValue = input.value;
    const inputValueLength = inputValue ? inputValue.length : 0;

    input.setSelectionRange(inputValueLength, inputValueLength);
    input.scrollLeft = input.scrollWidth;
  }

  onKeyUp(event) {
    if (event.keyCode === 13) {
      this.saveData();
    }
  }

  submit() {
    const { fieldKey, formatType, submitCallback } = this.props;
    const { value, oldValue, validation: { valid } } = this.state;
    let newValue = value;

    if (!submitCallback || oldValue === newValue) {
      return;
    }

    if (formatType === phoneType) {
      newValue = formatPhoneForRequest(newValue);
    }

    submitCallback(fieldKey, newValue, valid);

    this.setState({
      oldValue: value,
    });
  }

  handleChangeDate(date) {
    this.setState({
      value: date,
    }, this.saveData);
  }

  clearValidation() {
    this.setState({
      validation: {
        valid: true,
        validationMessage: '',
      },
    });
  }

  handleChange(event) {
    const { onChangeHook } = this.props;
    let { value } = event.target;
    const isValueEmpty = isEmpty(value);

    if (!isValueEmpty && value.trim && !value.trim()) {
      value = value.trim();
    }

    if (onChangeHook) {
      onChangeHook(value);
    }

    this.setState({
      [event.target.name]: value,
    }, this.clearValidation);
  }

  validate(submitCallback) {
    const { validationCallback } = this.props;

    if (!validationCallback) {
      if (submitCallback) {
        submitCallback();
      }

      return true;
    }
    const {
      type, maxDate, minDate, maxDateName, minDateName, dateName, dateRange,
    } = this.props;
    const { value } = this.state;
    let validationProps;

    if (type === 'date') {
      validationProps = {
        maxDate, minDate, maxDateName, minDateName, dateName, dateRange,
      };
    }

    const validationResult = validationCallback(value, validationProps);

    this.setState({
      validation: {
        valid: !validationResult,
        validationMessage: validationResult,
      },
    }, submitCallback);

    return !validationResult;
  }

  saveData() {
    this.validate(() => {
      const isValueEmpty = isEmpty(this.state.value);

      if (!isValueEmpty) {
        this.setState(state => ({
          editing: !state.validation.valid,
        }), this.submit);
      } else {
        this.submit();
      }
    });
  }

  renderDatePicker() {
    const {
      alwaysEditing, inputClassName, maxDate, minDate, isSortingYearAsc,
    } = this.props;
    const { editing, value } = this.state;
    let content;

    if (alwaysEditing || editing) {
      content = (
        <DatePicker
          key="datepicker"
          className={inputClassName || ''}
          value={value || null}
          changeCallback={this.handleChangeDate}
          name="value"
          maxDate={maxDate || null}
          minDate={minDate || null}
          alwaysEditing={alwaysEditing}
          clearValidationCallback={this.clearValidation}
          isSortingYearAsc={isSortingYearAsc}
        />
      );
    }

    return content;
  }

  render() {
    const {
      alwaysEditing, disabled, hidden, initialMaxViewLength,
      maxLength, placeholder, type, formatType, tooltip, fieldKey, dataTest,
    } = this.props;
    const { inputClassName, valueClassName, wrapperClassName } = this.props;
    const { editing, value, validation } = this.state;
    const maxViewLength = initialMaxViewLength || 30;
    const isValueEmpty = isEmpty(value);
    let content;
    let validationLabel;
    let className = wrapperClassName || '';
    const dataTestValue = dataTest || '';

    if (!disabled && (alwaysEditing || editing || isValueEmpty || hidden)) {
      if (type === 'date') {
        content = this.renderDatePicker();
      } else if (formatType === phoneType) {
        content = (
          <InputMask
            className={inputClassName || ''}
            value={value}
            disabled={hidden}
            onChange={this.handleChange}
            onFocus={this.onFocus}
            name="value"
            placeholder={placeholder || ''}
            onBlur={this.onBlur}
            onKeyUp={this.onKeyUp}
            mask={PHONE_MASK}
            maskChar=" "
            autoFocus={!isValueEmpty}
            data-test={dataTestValue}
          />
        );
      } else {
        content = (
          <input
            noValidate
            className={inputClassName || ''}
            value={value}
            disabled={hidden}
            onChange={this.handleChange}
            onFocus={this.onFocus}
            name="value"
            placeholder={placeholder || ''}
            onBlur={this.onBlur}
            type={type}
            autoFocus={!isValueEmpty}
            onKeyUp={this.onKeyUp}
            maxLength={maxLength}
            data-test={dataTestValue}
          />
        );
      }

      if (!validation.valid) {
        className += ' invalid';
        validationLabel = <div className="validation-label" data-test="editableInput_validation">{validation.validationMessage}</div>;
      }
    } else {
      let viewValue;
      switch (type) {
        case 'date': {
          viewValue = moment(value, DATE_FORMAT.FULL_SERVER).format(DATE_FORMAT.FULL);
          break;
        }
        case 'text': {
          const formattedValue = `${value}`;
          viewValue = formattedValue.slice(0, maxViewLength);
          if (value.length > viewValue.length) {
            viewValue += '...';
          }
          break;
        }
        case 'number': {
          viewValue = parseFloat(value);
          break;
        }
        default: {
          viewValue = value;
        }
      }

      if (tooltip) {
        content = (
          <div
            className={`text ${valueClassName || ''} ${disabled ? 'cursor-default' : ''}`}
            onClick={this.onClick}
            data-for={`tooltip-${fieldKey}`}
            data-tip={value}
          >
            {viewValue}
            <ReactTooltip id={`tooltip-${fieldKey}`} type="info" place="bottom" effect="float" />
          </div>
        );
      } else {
        content = (
          <div
            className={`text ${valueClassName || ''} ${disabled ? 'cursor-default' : ''}`}
            onClick={this.onClick}
          >
            {viewValue}
          </div>
        );
      }
    }

    return (
      <div className={`editable-input ${className}`}>
        {validationLabel}
        {content}
      </div>
    );
  }
}

export default EditableInput;
