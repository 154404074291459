// libraries
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
// constants
import { USER_ROLES } from '../../constants/constants';
// Components
import { withRouter } from '../shared/WithRouter';
// actions
import { BlockRouteTransitions, UnBlockRouteTransitions } from '../../actions/router';
import { PatientTimer } from '../../actions/patient';
// services
import { getTimeTrackingGracePeriod } from '../../services/administration';
import { getPatientId } from '../../services/helpers';

const INIT_TIME_FORMAT = '00:00';
const TOTAL_MAX_TIME = '999:59';
const MAX_TIME = '200:00';
const ELEMENT_NAME_TO_BLOCK = 'isTimer';

const numberFormat = (number, length = 2) => (`0${number}`).slice(-(length));

const getFormattedTime = (time) => {
  const seconds = Math.floor(time / 1000) % 60;
  const minutes = Math.floor(time / 60000) % 999;
  const minutesLength = minutes > 99 ? 3 : 2;

  return minutes > 999 ? TOTAL_MAX_TIME : `${numberFormat(minutes, minutesLength)}:${numberFormat(seconds)}`;
};

export class Stopwatch extends Component {
  state = {
    isTimerOn: true,
    savedTime: 0,
    formattedTime: INIT_TIME_FORMAT,
    isFullShown: false,
    timerGracePeriod: 0,
  };

  timer = null;

  componentDidMount() {
    // save data if a user reloads a page
    window.addEventListener('beforeunload', this.handleBeforeUnload);

    // Get the timer grace period
    this.loadTimeTrackingGracePeriod();
  }

  componentDidUpdate(prevProps) {
    const {
      patient, patientTimer, userRole, blockRouteTransitions,
    } = this.props;
    const { savedTime, isTimerOn, timerGracePeriod } = this.state;
    const isCnUser = (userRole === USER_ROLES.CN);
    const isPesUser = (userRole === USER_ROLES.PES);

    // Reset the timer if we're changing patients
    const patientId = getPatientId(patient);
    if (patientId !== getPatientId(prevProps.patient)) {
      this.resetTimer();
      // Start the timer automatically when opening a patient chart
      if (patientId && (isCnUser || isPesUser)) this.startTimer();
    }

    // Update the timer data for the patient
    const formattedTime = getFormattedTime(savedTime);
    const timer = patient?.timer;
    if (timer?.time !== formattedTime) {
      patientTimer({ time: formattedTime });
    }

    // Start or stop timer based on shared state
    const sharedIsTimerOn = timer?.isTimerOn;
    if (patientId !== undefined
        && sharedIsTimerOn !== undefined
        && sharedIsTimerOn !== isTimerOn) {
      if (sharedIsTimerOn && (isCnUser || isPesUser)) {
        this.startTimer();
      } else {
        this.stopTimer();
      }
    }

    // Check if we need to reset the timer
    if (timer?.reset) {
      this.resetTimer();
    }

    // Check if we need to block transitions due to grace period
    if (!timer?.reset && savedTime > timerGracePeriod) {
      // Notify that there is timer data to notify user about
      blockRouteTransitions(ELEMENT_NAME_TO_BLOCK);
    }
  }

  componentWillUnmount() {
    const { unBlockRouteTransitions } = this.props;
    unBlockRouteTransitions(ELEMENT_NAME_TO_BLOCK);
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  }

  handleBeforeUnload = (event) => {
    const { savedTime, timerGracePeriod } = this.state;
    if (savedTime > 0 && (timerGracePeriod === 0 || savedTime > timerGracePeriod)) {
      // Prevent default to close
      event.preventDefault();
      // For legacy browsers
      // eslint-disable-next-line no-param-reassign
      event.returnValue = true;
    }
  };

  startTimer = () => {
    const { patientTimer } = this.props;
    this.setState(state => ({
      isTimerOn: true,
      savedTime: state.savedTime,
      timerStart: moment().diff(state.savedTime),
      isFullShown: true,
    }));
    patientTimer({ isTimerOn: true });

    this.setTimer();
  };

  stopTimer = () => {
    clearInterval(this.timer);
    this.setState({ isTimerOn: false });
  };

  resetTimer = () => {
    const { unBlockRouteTransitions, patientTimer } = this.props;
    clearInterval(this.timer);
    patientTimer({ reset: false });
    this.setState({
      isTimerOn: false,
      savedTime: 0,
      formattedTime: INIT_TIME_FORMAT,
      isFullShown: false,
    });
    unBlockRouteTransitions(ELEMENT_NAME_TO_BLOCK);
  };

  setTimer = () => {
    this.timer = setInterval(() => {
      const savedTime = moment().diff(this.state.timerStart);
      const formattedTime = getFormattedTime(savedTime);

      this.setState({
        savedTime,
        formattedTime,
      }, (formattedTime === MAX_TIME || formattedTime === TOTAL_MAX_TIME) ? this.stopTimer : null);
    }, 500);
  };

  loadTimeTrackingGracePeriod() {
    const getGracePeriodRequest = getTimeTrackingGracePeriod();
    const getGracePeriodPromise = getGracePeriodRequest.promise;

    getGracePeriodPromise.then((data) => {
      if (data) {
        const timerGracePeriod = data?.value ? data?.value * 1000 : 0;
        this.setState({
          timerGracePeriod,
        });
      }
    });
  }

  render() {
    const { userRole, patient } = this.props;
    const isCnUser = (userRole === USER_ROLES.CN);
    const isPesUser = (userRole === USER_ROLES.PES);
    const { isFullShown, formattedTime, isTimerOn } = this.state;
    let renderBlock = '';

    if (isFullShown) {
      renderBlock = (
        <div className="stopwatch-timer">
          <span className="mr-2">
            {formattedTime}
          </span>
          <i className="bi bi-stopwatch" style={isTimerOn ? { color: 'green' } : { color: 'red' }} />
        </div>
      );
    }
    // Only display timer when on a patient and if a user is a CN or PES
    if (getPatientId(patient) && (isCnUser || isPesUser)) {
      return (
        <Fragment>
          {renderBlock}
        </Fragment>);
    }
    return (<></>);
  }
}

function mapStateToProps(state) {
  return {
    patient: state.patient,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    blockRouteTransitions: elem => dispatch(BlockRouteTransitions(elem)),
    unBlockRouteTransitions: elem => dispatch(UnBlockRouteTransitions(elem)),
    patientTimer: elem => dispatch(PatientTimer(elem)),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Stopwatch));
