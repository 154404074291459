import { createAction, createReducer } from '@reduxjs/toolkit';

const showBanner = createAction('SHOW_BANNER');
const hideBanner = createAction('HIDE_BANNER');

const initialState = {
  message: '',
  shown: false,
  canHide: true,
  bannerType: '',
};

const Banner = createReducer(initialState, (builder) => {
  builder
    .addCase(showBanner, (state, action) => {
      state.message = action.message;
      state.shown = true;
      state.canHide = action.canHide;
      state.bannerType = action.bannerType;
    })
    .addCase(hideBanner, () => ({ ...initialState }))
    .addDefaultCase(state => state);
});

export default Banner;
