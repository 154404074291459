// Libraries
import React, {
  Suspense, lazy, useEffect, useState,
} from 'react';
import _sortBy from 'lodash/sortBy';
import _groupBy from 'lodash/groupBy';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import { Button, Dropdown } from 'react-bootstrap';
// Constants
import { EMPTY_STRING, DATE_FORMAT } from '../../../../constants/constants';
import useLoadPatientScreening from '../../../../hooks/services/useLoadPatientScreening';
// Component
import { Loading } from '../../../base/Loading';
// Lazy
const ScreeningModal = lazy(() => import('./ScreeningModal'));
const ScreeningViewModal = lazy(() => import('./ScreeningViewModal'));

const defaultYear = moment.utc().format(DATE_FORMAT.YEAR);

export const Screening = (props) => {
  const { patientId, loadPatientData, isReadOnly = false } = props;
  const { screenings, refetch } = useLoadPatientScreening({ patientId });
  const SCREENING_FILTER = { [defaultYear]: { name: defaultYear } };

  const screeningsWithYear = screenings && screenings.map(
    item => ({
      ...item,
      createdAtYear: moment(item.createdAt, DATE_FORMAT.FULL_SERVER_SHORT).format(DATE_FORMAT.YEAR),
    }),
  );
  const screeningsYearGrouped = _groupBy(screeningsWithYear, item => item.createdAtYear);
  Object.keys(screeningsYearGrouped).forEach((year) => {
    SCREENING_FILTER[year] = {
      name: `${year}`,
    };
  });

  const [modalsOpened, setModalsOpened] = useState([]);
  const [screeningsFilter, setScreeningsFilter] = useState(defaultYear);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [selectedScreening, setSelectedScreening] = useState({});

  const handleViewScreening = (screening) => {
    setSelectedScreening(screening);
    setIsViewModalOpen(true);
  };

  const handleAddScreening = () => {
    setIsModalOpen(true);
  };

  const renderDropDownItems = Object.keys(SCREENING_FILTER).map((key, index) => {
    const yearName = defaultYear === key ? 'This Year' : key;
    return (
      <Dropdown.Item
        key={`screening_filter_${index}`}
        as="button"
        className="d-flex my-1"
        onClick={() => {
          setScreeningsFilter(SCREENING_FILTER[key].name);
        }}
        data-test="screening_dateFilterItem"
      >
        <div className="pr-3">{yearName}</div>
      </Dropdown.Item>
    );
  });

  const renderEmptyRow = () => (
    <tr data-test="screening_emptyMsg">
      <td colSpan="5" className="p-2 border-0">
        This patient has no recorded screenings
      </td>
    </tr>
  );

  const renderRows = () => {
    if (screeningsYearGrouped[screeningsFilter] && screeningsYearGrouped[screeningsFilter].length) {
      const sortedScreeningsByDate = _sortBy(
        screeningsYearGrouped[screeningsFilter], el => moment(el.date),
      );
      return sortedScreeningsByDate.map((screening, index) => {
        const dateString = screening.createdAt
          ? moment.utc(screening.createdAt, DATE_FORMAT.FULL_WITH_TIME_IN_12_HOURS)
            .format(DATE_FORMAT.FULL_WITH_TIME_IN_12_HOURS) : EMPTY_STRING;

        const onRowClick = () => handleViewScreening(screening);

        return (
          <tr key={`screening_row_${index}_${screening.id}`} data-test="screening_entryRow" className="no-editable">
            <td>
              {dateString}
            </td>
            <td>
              {screening.description}
            </td>
            <td>
              <span className="text-capitalize">{(screening.escalationPriority && screening.escalationPriority.toLowerCase()) || EMPTY_STRING}</span>
            </td>
            <td>
              {screening.performedBy || EMPTY_STRING}
            </td>
            <td className="text-right">
              <Button onClick={() => onRowClick()} variant="link" className="px-1" data-test="screening_viewBtn">
                view
              </Button>
            </td>
          </tr>
        );
      });
    }
    return renderEmptyRow();
  };

  useEffect(() => {
    if (isModalOpen && !modalsOpened.includes('isModalOpen')) {
      setModalsOpened([...modalsOpened, 'isModalOpen']);
    }
    if (isViewModalOpen && !modalsOpened.includes('isViewModalOpen')) {
      setModalsOpened([...modalsOpened, 'isViewModalOpen']);
    }
  }, [isModalOpen, isViewModalOpen]);

  return (
    <div className="card border-0">
      <div className="card-header rounded-0 bg-ccm-light-gray border text-ccm-bismark d-flex-center py-1">
        <Dropdown
          className="position-absolute"
          style={{ left: 1 }}
          data-test="screening_dateFilter"
        >
          <Dropdown.Toggle
            variant="ccm-light-gray"
            className="py-0"
          >
            {`Filter: ${defaultYear === screeningsFilter ? 'This Year' : screeningsFilter}`}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {renderDropDownItems}
          </Dropdown.Menu>
        </Dropdown>
        <span className="text-uppercase">Screenings</span>
        <Button
          size="sm"
          variant="link-dark"
          className="position-absolute"
          style={{ right: 10 }}
          onClick={() => handleAddScreening()}
          data-test="screening_addBtn"
          disabled={isReadOnly}
        >
          <i className="d-flex-center bi-plus-lg" />
        </Button>
      </div>
      <div className="card-body ccm-table-container px-0">
        <table className="table w-100 text-left table-hover">
          <thead data-test="screening_allFields">
            <tr>
              <th className="py-1 text-uppercase">Date</th>
              <th className="py-1 text-uppercase">Description</th>
              <th className="py-1 text-uppercase">Outcome</th>
              <th className="py-1 text-uppercase">Performed By</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {renderRows()}
          </tbody>
        </table>
        <Suspense fallback={<Loading forceLoading />}>
          { modalsOpened.includes('isModalOpen') && (
            <ScreeningModal
              patientId={patientId}
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              callBackAction={refetch}
              loadPatientData={loadPatientData}
            />
          )}
          { modalsOpened.includes('isViewModalOpen') && (
            <ScreeningViewModal
              isModalOpen={isViewModalOpen}
              setIsModalOpen={setIsViewModalOpen}
              selectedScreening={selectedScreening}
            />
          )}
        </Suspense>
        <ReactTooltip id="tooltip-cpInterventions" type="info" effect="float" place="bottom" />
      </div>
    </div>
  );
};

export default Screening;
