// Libraries
import React, { Fragment, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { Button } from 'react-bootstrap';
// Services
import { updateNextAppointment } from '../../../services/patient';
// Views
import AnnualWellnessVisitModal from './AnnualWellnessVisitModal';
import InterventionsModal from '../carePlan/interventions/InterventionModal';
// Actions
import ShowNotification from '../../../actions/notification';
import { UpdatePatient } from '../../../actions/patient';
// Constants
import {
  DATE_FORMAT, NONE_SPECIFIED_STRING, NOTIFICATION_TYPE,
  EMPTY_STRING, USER_ROLES, TENANT_FEATURES_NAMES,
} from '../../../constants/constants';
import { INTERVENTION_SUBCATEGORIES } from '../../../constants/interventions';

export const SummaryAnnualWellness = () => {
  const { id: patientId } = useParams();
  const {
    patient: {
      lastAwvDate, nextWellnessVisit, currentRafScore, potentialRafUplift,
    },
    tenant: { features, isReadOnly = false },
    user,
  } = useSelector(state => state);
  const dispatch = useDispatch();
  const showNotification = data => dispatch(ShowNotification(data));
  const [isInterventionsModalOpen, setIsInterventionsModalOpen] = useState(false);
  const [isAnnualWVModalOpened, setIsAnnualWVModalOpened] = useState(false);
  const [initialIntervention, setInitialIntervention] = useState(false);

  const showRafScores = features
    && features.find(f => f.featureName === TENANT_FEATURES_NAMES.SHOW_RAF_SCORES.name).enabled;
  const isAdminUser = user.role === USER_ROLES.ADMIN;

  const getAWVColor = (date) => {
    const betweenTwelveAndNineMonths = (moment(date, DATE_FORMAT.FULL).isBetween(moment().subtract(12, 'months'), moment().subtract(9, 'months')));
    const notBetweenTwelveAndNow = !moment(date, DATE_FORMAT.FULL).isBetween(moment().subtract(12, 'months'), moment());
    if (betweenTwelveAndNineMonths) {
      return 'text-ccm-orange';
    }
    if (notBetweenTwelveAndNow) {
      return 'text-ccm-red';
    }
    return 'text-ccm-dark';
  };

  const handleAddIntervention = (lastAWV, nextAWV) => {
    let interventionNote = `Last AWV Date: ${lastAWV ? moment(lastAWV, DATE_FORMAT.FULL).format(DATE_FORMAT.FULL) : 'Unknown'}`;
    interventionNote += `${nextAWV ? `, Next AWV Date: ${moment(nextAWV, DATE_FORMAT.FULL_WITH_TIME_IN_12_HOURS).format(DATE_FORMAT.FULL)}` : ''}`;
    setInitialIntervention({
      type: 'AWV',
      title: INTERVENTION_SUBCATEGORIES.AWV[0],
      note: interventionNote,
    });
    setIsInterventionsModalOpen(true);
  };

  const nextAWVDate = nextWellnessVisit && nextWellnessVisit.nextAppointmentDate
    ? moment(nextWellnessVisit.nextAppointmentDate, DATE_FORMAT.FULL_SERVER_WITH_TIME)
      .format(DATE_FORMAT.FULL_WITH_TIME_IN_12_HOURS)
    : '';

  const nextAWVMonthDifference = nextAWVDate ? moment(nextAWVDate, DATE_FORMAT.FULL_WITH_TIME_IN_12_HOURS).diff(moment(), 'months') : 0;
  const potentialRafUpliftUpdated = potentialRafUplift > 0 ? `+${potentialRafUplift}` : potentialRafUplift;

  const clearNextAppointment = (nextAppointment, nextAppointmentKey) => {
    const clearedAppointment = {
      id: nextAppointment.id,
      physician: { id: null },
      nextAppointmentDate: null,
    };

    if (nextAppointment && nextAppointment.id) {
      const nextAppointmentRequest = updateNextAppointment(clearedAppointment);
      const nextAppointmentPromise = nextAppointmentRequest.promise;

      return nextAppointmentPromise.then((data) => {
        delete nextAppointmentRequest.promise;

        dispatch(UpdatePatient({ [nextAppointmentKey]: data }));
      }).catch((error) => {
        delete nextAppointmentRequest.promise;
        if (error.isCanceled || error.status === 401 || error.status === 403) {
          return;
        }
        showNotification({
          message: 'An error occurred while attempting to save next appointment',
          autoHide: true,
          notificationType: NOTIFICATION_TYPE.ERROR,
        });
      });
    }
    showNotification({
      message: 'No Next Appointment has been created',
      autoHide: true,
      notificationType: NOTIFICATION_TYPE.ERROR,
    });
    return {};
  };

  return (
    <Fragment>
      <div className="d-flex justify-content-between">
        <span>Annual Wellness Visit</span>
        <Button
          size="lg"
          variant="link"
          className="p-0 m-0"
          onClick={() => setIsAnnualWVModalOpened(true)}
          data-test="summaryAnnualWellness_setNextAWVButton"
          disabled={isReadOnly}
        >
          <span className="d-flex-center bi-pencil-square" />
        </Button>
      </div>
      <div>
        <span className="text-ccm-gray mr-2">Last AWV:</span>
        <span className={`mr-2 ${lastAwvDate && getAWVColor(lastAwvDate)}`} data-test="summaryAnnualWellness_lastAwvDate">
          {lastAwvDate ? moment(lastAwvDate, DATE_FORMAT.FULL).format(DATE_FORMAT.FULL) : 'Unknown'}
        </span>

        {(!lastAwvDate || !moment(lastAwvDate, DATE_FORMAT.FULL).isBetween(moment().subtract(9, 'months'), moment()))
          && (<Button variant="link-dark" className="px-1" onClick={() => handleAddIntervention(lastAwvDate, nextAWVDate)} data-test="summaryAnnualWellness_addInterventionBtn" disabled={isReadOnly}>
            <i className="bi-plus-circle-fill" />
          </Button>
          )}
      </div>
      <div className="d-flex align-items-center">
        <div>
          <span className="mr-2">Next AWV:</span>
          <span className={`next-apt-visit${(nextAWVMonthDifference > 9 && nextAWVMonthDifference < 12) ? ' text-ccm-orange ' : ''}`} data-test="summaryAnnualWellness_inputNextAWVButton" onClick={!isReadOnly ? () => setIsAnnualWVModalOpened(true) : null}>{nextAWVDate || NONE_SPECIFIED_STRING}</span>
        </div>
        {nextAWVDate && (
          <Button
            size="lg"
            variant="link"
            className="p-0 ml-3"
            onClick={() => clearNextAppointment(nextWellnessVisit, 'nextWellnessVisit')}
            data-test="summaryAnnualWellness_clearAnnualWellnessVisitButton"
            disabled={isReadOnly}
          >
            <span className="d-flex-center bi-x" />
          </Button>)}
      </div>
      { showRafScores && isAdminUser && (
        <Fragment>
          <div className="d-flex align-items-center">
            <span className="mr-2">Current RAF Score:</span>
            <span className="">{currentRafScore || EMPTY_STRING}</span>
          </div>
          <div className="d-flex align-items-center">
            <span className="mr-2">Potential RAF Uplift:</span>
            <span className="">{potentialRafUpliftUpdated || EMPTY_STRING}</span>
          </div>
        </Fragment>
      )}

      <AnnualWellnessVisitModal
        initialAppointment={nextWellnessVisit}
        isModalOpen={isAnnualWVModalOpened}
        setIsModalOpen={setIsAnnualWVModalOpened}
      />
      <InterventionsModal
        patientId={patientId}
        initialIntervention={initialIntervention}
        isModalOpen={isInterventionsModalOpen}
        setIsModalOpen={setIsInterventionsModalOpen}
      />
    </Fragment>
  );
};

export default SummaryAnnualWellness;
