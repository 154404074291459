// Libraries
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Button } from 'react-bootstrap';
import _camelCase from 'lodash/camelCase';
// Constants
import { DATE_FORMAT } from '../../../constants/constants';

export const PatientAlertRow = (props) => {
  const {
    collapseAll,
    alert = {},
    removePatientAlert,
    disabled = false,
  } = props;
  const [opened, setOpened] = useState(true);

  useEffect(() => {
    setOpened(!collapseAll);
  }, [collapseAll]);

  return (
    <div className={`row no-gutters w-100 mb-2 recentWorkRow-${opened ? 'opened' : 'closed'}`}>
      <div className="col alert-list-info border text-left p-3">
        {!opened && (
        <p className="mb-0">
          Alert on:&nbsp;
          <span className="recentWork_activityDate">{moment(alert.createdAt).format(DATE_FORMAT.FULL)}</span>
        </p>
        )}
        {opened && (
          <div key={`patientAlert__${alert.patientAlertid}${_camelCase(alert.createdAt)}`} className="mb-3" data-test="patientAlertRow_alertItem">
            <p className="alert__alertDate mb-2" data-test="patientAlertRow_alertDate">{moment(alert.createdAt).format(DATE_FORMAT.FULL)}</p>
            <ul className="mb-0">
              <li key={`alertInfo__${alert.patientAlertid}${_camelCase(alert.description)}`} data-test="patientAlertRow_InfoItem">
                {alert.description}
                <br />
                {alert.linkUrl && (
                  <a href={alert.linkUrl} rel="noopener noreferrer" target="_blank">
                    {alert.linkDisplayText ? alert.linkDisplayText : 'Link to Pre-Visit Screening Form'}
                  </a>
                )}
              </li>
            </ul>
          </div>)}
        <Button
          size="md"
          variant="link-dark"
          className="d-flex-center position-absolute p-1"
          style={{ top: '1rem', right: '3rem' }}
          onClick={() => removePatientAlert(alert.patientAlertid)}
          data-test="patientAlertRow_removeRowButton"
          disabled={disabled}
        >
          <i className="d-flex-center bi bi-x-lg" />
        </Button>
        <Button
          size="sm"
          variant="link-dark"
          className="d-flex-center position-absolute"
          style={{ top: '1rem', right: '1rem' }}
          onClick={() => setOpened(!opened)}
          data-test="patientAlertRow_collapseRowButton"
        >
          <i className={`d-flex-center bi-caret-${opened ? 'down' : 'up'}-fill ml-1`} />
        </Button>
      </div>
    </div>
  );
};

export default PatientAlertRow;
