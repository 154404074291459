// Libraries
import React, { useState, Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import _isEmpty from 'lodash/isEmpty';
// Services
import { getBillingInfo } from '../../../services/patient';
// Views
import BillingDiagnoses from './billing/BillingDiagnoses';
import ConfirmBilling from './billing/ConfirmBilling';
import History from './billing/History';
// Actions
import { BlockRouteTransitions, UnBlockRouteTransitions } from '../../../actions/router';
import { UpdatePatient } from '../../../actions/patient';
// Constants
import {
  BILLING_FORM_ERRORS, NOTIFICATION_TYPE,
} from '../../../constants/constants';

const ELEMENT_NAME_TO_BLOCK = 'isBilling';

export function BillingSection(props) {
  const {
    patientId, patient, updatePatient,
    blockTransitions, unblockTransitions,
    showNotification, patient: { billing = {} } = {},
    prevPhysicianName, setPrevPhysicianName, setDisplayPcpMessage, isReadOnly = false,
  } = props;
  const [validationErrors, setValidationErrors] = useState([]);

  const billingConfirmed = (patient && patient.billing && patient.billing.issues && !patient.billing.issues.includes('BILLING_PROBLEMS_ARE_NOT_CONFIRMED'));
  const physicianName = billing && billing.billingInfo && billing.billingInfo.physicianDisplayName ? billing.billingInfo.physicianDisplayName : '';

  const [editMode, setEditMode] = useState(!billingConfirmed);

  const loadBillingInfo = () => {
    const getBillingInfoRequest = getBillingInfo(patientId);
    const getBillingInfoPromise = getBillingInfoRequest.promise;

    getBillingInfoPromise.then((data) => {
      delete getBillingInfoRequest.promise;

      let problems = [];
      if (data.problems && data.problems.length) {
        problems = data.problems.map(problem => ({
          ...problem,
          newIndex: `pc${problem.id}`,
        }));
      }

      const billingInfo = {
        ...data,
        problems,
      };
      updatePatient({ billing: { ...billing, billingInfo } });
    }).catch((error) => {
      delete getBillingInfoRequest.promise;
      if (error.isCanceled) {
        return;
      }
      if (error.status === 401 || error.status === 403) {
        return;
      }
      showNotification({
        message: 'Could not load billing information, please try again later',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  useEffect(() => () => {
    unblockTransitions(ELEMENT_NAME_TO_BLOCK);
  }, []);

  const renderValidationErrors = () => {
    let billingFormErrorsBlock = null;

    if (validationErrors.length) {
      const errorBlocks = validationErrors.map((validationError, index) => (
        <li data-test="billingSection_minMaxErrorMessage" key={`billing-validation-error-${index}`}>
          <span className="i-close mr-1" />
          {BILLING_FORM_ERRORS[validationError]}
        </li>
      ));

      billingFormErrorsBlock = (
        <div className="mb-4 text-ccm-red">
          <div data-test="billingSection_someErrorMessage">There are some errors, please correct them:</div>
          <ul className="pl-1 mt-2">
            {errorBlocks}
          </ul>
        </div>
      );
    }
    return billingFormErrorsBlock;
  };

  return (
    <Fragment>
      <div className="d-flex justify-content-end mb-2">
        <History patientId={patientId} />
        {
          editMode && !isReadOnly ? (
            <Fragment>
              <ConfirmBilling
                data-test="billingSection_confirmBilling"
                patientId={patientId}
                setValidationErrors={setValidationErrors}
                prevPhysicianName={prevPhysicianName}
                setDisplayPcpMessage={setDisplayPcpMessage}
                confirmSuccessCallback={() => {
                  setEditMode(false);
                  unblockTransitions(ELEMENT_NAME_TO_BLOCK);
                }}
              />
              {billingConfirmed && (
                <Button
                  data-test="billingSection_cancelButton"
                  variant="primary"
                  size="sm"
                  className="ml-2"
                  onClick={() => {
                    setEditMode(false);
                    setValidationErrors([]);
                    unblockTransitions(ELEMENT_NAME_TO_BLOCK);
                    loadBillingInfo();
                  }}
                >
                  Cancel
                </Button>
              )}
            </Fragment>
          ) : (
            <Button
              data-test="billingSection_editButton"
              variant="primary"
              size="sm"
              className="ml-2"
              onClick={() => {
                setEditMode(true);
                setPrevPhysicianName(physicianName);
                blockTransitions(ELEMENT_NAME_TO_BLOCK, true);
              }}
              disabled={isReadOnly}
            >
              Edit
            </Button>
          )
        }
      </div>
      {billing && !_isEmpty(billing.billingInfo) && (
        <BillingDiagnoses
          patientId={patientId}
          editMode={editMode && !isReadOnly}
        />)
      }
      {renderValidationErrors()}
    </Fragment>
  );
}

export function mapStateToProps(state) {
  return {
    patient: state.patient,
    userRole: state.user && (state.user.role || ''),
    isReadOnly: state.tenant && state.tenant.isReadOnly,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updatePatient: patientData => dispatch(UpdatePatient(patientData)),
    blockTransitions: (elem, value) => dispatch(BlockRouteTransitions(elem, value)),
    unblockTransitions: elem => dispatch(UnBlockRouteTransitions(elem)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BillingSection);
