// Libraries
import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment-timezone';
import { Button, Row, Form } from 'react-bootstrap';
// Actions
import ShowNotification from '../../../../actions/notification';
import { UpdatePatient } from '../../../../actions/patient';
import { getIcd10Regex } from '../../../../services/administration';
// Constants
import {
  NOTIFICATION_TYPE, DIALOG_STYLES, DATE_FORMAT,
} from '../../../../constants/constants';
import { NON_PRIMARY_DX_CODES } from '../../../../constants/codes/nonPrimaryDxCodes';
// Components
import { TextInput } from '../../../base/forms/TextInput';
import { Datepicker } from '../../../base/forms/Datepicker';
// Services
import { validateICD10CodeYup } from '../../../../services/helpers';

const VALID_PROBLEM_CODE = 'ICD10';

export const validateUniqueCode = (code, billing, newDiagnosis) => {
  const repeatedCode = billing && billing.billingInfo.problems.filter(p => p.code === code);
  return repeatedCode.length
    ? (
      repeatedCode[0].id === newDiagnosis.id
      && !(repeatedCode[0].isNew && repeatedCode[0].name !== newDiagnosis.name)
    ) : true;
};


export function DiagnosisModal(props) {
  const {
    isModalOpen, setIsModalOpen, initialDiagnosis, timezone, showNotification, updatePatient,
    patient: { billing } = {},
  } = props;

  const [newProblemsCounter] = useState(0);

  const DEFAULT_DIAGNOSIS = {
    id: '',
    name: '',
    newIndex: `np${newProblemsCounter}`,
    code: '',
    isNew: true,
    startedAt: null,
    isEdit: false,
  };

  const [icd10Regex, setIcd10Regex] = useState([]);

  function loadIcd10ValidationRegex() {
    useEffect(() => {
      const getICD10RegexRequest = getIcd10Regex();
      const getICD10RegexPromise = getICD10RegexRequest.promise;

      getICD10RegexPromise.then((data) => {
        if (data) {
          const icd10ValidationString = data?.value ? data?.value : '';
          const icd10ValidationRegex = new RegExp(icd10ValidationString);
          setIcd10Regex(icd10ValidationRegex);
        }
      });
    }, []);
  }

  const diagnosisSchema = newDiagnosis => Yup.object({
    name: Yup.string()
      .required('Required'),
    code: Yup.string()
      .required('Required')
      .test('Unique Code',
        'The code must be unique',
        value => validateUniqueCode(value, billing, newDiagnosis))
      .test('validateICD10Code',
        'must be a valid ICD10 code',
        value => validateICD10CodeYup(value, icd10Regex)),
    startedAt: Yup.date('Must be a valid date')
      .nullable()
      .default(undefined),
  });

  const [newDiagnosis, setNewDiagnosis] = useState(initialDiagnosis || DEFAULT_DIAGNOSIS);

  loadIcd10ValidationRegex();

  useEffect(() => {
    if (initialDiagnosis) {
      const isValidProblem = initialDiagnosis.primaryCodeSystem === VALID_PROBLEM_CODE;
      const diagnoseInfo = (initialDiagnosis.id && isValidProblem) ? { ...initialDiagnosis } : null;
      setNewDiagnosis({
        id: initialDiagnosis.id || '',
        name: initialDiagnosis.name || '',
        code: initialDiagnosis.code || '',
        newIndex: initialDiagnosis.id ? `np${initialDiagnosis.id}` : `np${newProblemsCounter}`,
        isNew: !initialDiagnosis.id,
        startedAt: initialDiagnosis.startedAt ? moment(initialDiagnosis.startedAt).tz(timezone)
          .format(DATE_FORMAT.FULL) : null,
        isEdit: initialDiagnosis.isEdit || false,
        diagnoseInfo,
      });
    } else {
      setNewDiagnosis(DEFAULT_DIAGNOSIS);
    }
  }, [initialDiagnosis]);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const isPrimaryDxInvalid = diagnosis => NON_PRIMARY_DX_CODES.includes(diagnosis.code);

  const addTempDiagnosis = (diagnosis) => {
    const { billingInfo } = billing;
    if (isPrimaryDxInvalid(diagnosis) && diagnosis.order === 1) {
      showNotification({
        message: `Unacceptable principal diagnosis: ${diagnosis.code}. Please correct before adding this diagnosis.`,
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
      return;
    }

    const billingFormProblems = billingInfo.problems.map((problem, index) => {
      if (
        (diagnosis.isEdit && diagnosis.id && diagnosis.id === problem.id)
        || (diagnosis.isEdit && diagnosis.code === problem.code)
      ) {
        return ({
          ...problem,
          ...diagnosis,
          order: index + 1,
        });
      }
      return {
        ...problem,
        order: index + 1,
      };
    });

    if (!diagnosis.isEdit) {
      const newProblem = {
        ...diagnosis,
        order: billingFormProblems.length + 1,
      };

      billingFormProblems.push(newProblem);
    }
    updatePatient(
      {
        billing: {
          ...billing, billingInfo: { ...billingInfo, problems: [...billingFormProblems] },
        },
      },
    );

    handleCloseModal();
  };

  return (
    <Modal
      isOpen={isModalOpen}
      style={DIALOG_STYLES}
      onRequestClose={() => handleCloseModal()}
      ariaHideApp={false}
      contentLabel="CCM Billing Diagnosis"
      data-test="diagnosisModal_onRequestClose"
    >
      <div className="simple-dialog small-dialog">
        <div className="dialog-title">
          CCM Billing Diagnosis
          <button
            type="button"
            className="close-icon i-close"
            onClick={() => handleCloseModal()}
            data-test="diagnosisModal_closeButton"
          />
        </div>
        <Formik
          data-test="diagnosisModal_formikElement"
          initialValues={newDiagnosis}
          validationSchema={diagnosisSchema(billing, newDiagnosis)}
          onSubmit={(values) => {
            const formattedDate = values.startedAt ? moment(values.startedAt).format(DATE_FORMAT.FULL_SERVER) : '';
            const newValues = { ...values, startedAt: formattedDate };
            addTempDiagnosis(newValues);
          }}
        >
          {formik => (
            <Form>
              <div className="dialog-content">
                <Row className="text-left align-items-center">
                  <div className="col">
                    <TextInput
                      label="PROBLEM"
                      name="name"
                      data-test="diagnosisModal_problemInput"
                    />
                  </div>
                </Row>
                <Row className="text-left align-items-center">
                  <div className="col">
                    <TextInput
                      label="CODE"
                      name="code"
                      data-test="diagnosisModal_codeInput"
                    />
                  </div>
                </Row>
                <Row className="text-left align-items-center">
                  <div className="col" data-test="diagnosisModal_dateInput">
                    <Datepicker
                      label="ONSET"
                      name="startedAt"
                      data-test="diagnosisModal_onsetInput"
                    />
                  </div>
                </Row>
              </div>
              <div className="dialog-buttons justify-content-end px-4">
                <Button variant="light" onClick={() => handleCloseModal()} data-test="diagnosisModal_cancelButton">Cancel</Button>
                <Button
                  variant="primary"
                  className="ml-2"
                  onClick={() => formik.handleSubmit()}
                  disabled={!formik.isValid}
                  data-test="diagnosisModal_saveButton"
                >
                  Save
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
}

export function mapStateToProps(state) {
  return {
    timezone: state.tenant && state.tenant.timezone,
    patient: state.patient,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updatePatient: patientData => dispatch(UpdatePatient(patientData)),
    showNotification: notificationData => dispatch(ShowNotification(notificationData)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DiagnosisModal);
