// libraries
import React from 'react';
import { connect } from 'react-redux';
import { Alert, Button } from 'react-bootstrap';
// actions
import { HideBanner } from '../../actions/banner';

const typeToClassMap = {
  ERROR: 'danger',
  SUCCESS: 'success',
  WARNING: 'warning',
};

export function Banner(props) {
  const {
    shown, message, bannerType, hideBanner, canHide,
  } = props;

  return (
    <div className={shown ? 'ccm-banner-container' : ''}>
      <Alert
        show={shown}
        className={canHide ? 'd-flex-center-between' : 'text-center mb-0'}
        variant={typeToClassMap[bannerType]}
      >
        <p className={canHide ? 'text-left m-0' : 'text-center m-0'} data-test="banner_toastMsg">{message}</p>
        {canHide && (
          <Button
            className="p-0 ml-2"
            data-test="banner_closeToastMsg"
            onClick={() => hideBanner()}
            variant={`outline-${typeToClassMap[bannerType]}`}
          >
            <i className="d-flex-center bi-x" />
          </Button>
        )}
      </Alert>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    message: state.banner.message,
    canHide: state.banner.canHide,
    shown: state.banner.shown,
    bannerType: state.banner.bannerType,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    hideBanner: () => dispatch(HideBanner()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Banner);
